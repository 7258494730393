export const validations = {
    required: {
        validation: function(p = {}) {
            const required = p.required;
            return function (value) {
                if (required){
                    if (value === null || value === "" && value !== 0 || typeof value === "undefined") {
                        return false;
                    }
                }
                return true;
            }
        },
        error: function(p = {}){
            const required = p.required;
            if (required){
                return "required value"
            } else {
                return "bad configuration for required [" + required.toString() + "]";
            }
        },
    },
    min: {
        validation: function(p = {}) {
            const n = p.value || 0;
            const t = p.type;
            return function (value) {
                const type = typeof value;
                if (t){
                    if (p.enableNullishValue){
                        if (!value && t !== type) {
                            return true;
                        }
                    }
                    if (value && type === "object" && t === type && value.length > n - 1) return true;
                    if (value && type === "string" && t === type && value.length > n - 1) return true;
                    if (value && type === "number" && t === type && value > n - 1 || value === 0 && type === "number" && value > n - 1) return true;
                    if (value && t === "number" && type === "string" && !isNaN(Number(value))){
                        if (Number(value) > n - 1) return true;
                    }
                } else {
                    if (p.enableNullishValue){
                        if (!value) {
                            return true;
                        }
                    }
                    if (value && type === "object" && value.length > n - 1) return true;
                    if (value && type === "string" && value.length > n - 1) return true;
                    if (value && type === "number" && value > n - 1 || value === 0 && type === "number" && value > n - 1) return true;
                }
                return false;
            }
        },
        error: function(p = {}){
            const n = p.value || 0;
            return "minimum " + n;
        },
    },
    max: {
        validation: function(p = {}){
            const n = p.value || 0;
            const t = p.type;
            return function(value){
                const type = typeof value;
                if (t){
                    if (p.enableNullishValue){
                        if (!value && t !== type) {
                            return true;
                        }
                    }
                    if (value && type === "object" && t === type && value.length < n + 1) return true;
                    if (value && type === "string" && t === type && value.length < n + 1) return true;
                    if (value && type === "number" && t === type && value < n + 1 || value === 0 && type === "number" && value < n + 1) return true;
                    if (value && t === "number" && type === "string" && !isNaN(Number(value))){
                        if (Number(value) < n + 1) return true;
                    }
                } else {
                    if (p.enableNullishValue){
                        if (!value) {
                            return true;
                        }
                    }
                    if (value && type === "object" && value.length < n + 1) return true;
                    if (value && type === "string" && value.length < n + 1) return true;
                    if (value && type === "number" && value < n + 1 || value === 0 && type === "number" && value < n + 1) return true;
                }
                return false;
            }
        },
        error: function(p = {}){
            const n = p.value || 0;
            return "maximum " + n;
        },
    },
    url: {
        validation: function(){
            return function(value) {
                const matcher = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
                return matcher.test(value);
            }
        },
        error: function(){
            return "invalid url";
        },
    },
    urlOrEmpty: {
        validation: function(){
            return function(value) {
                if (!value) return true;
                const matcher = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
                return matcher.test(value);
            }
        },
        error: function(){
            return "invalid url";
        },
    },
    type: {
        validation: function(p = {}){
            const t = p.value || [];
            return function(value){
                const type = typeof value;
                if (!t) return true;
                if (typeof t !== "object") return true;
                if (!t.length) return true;
                if (t.indexOf(type) > -1) return true;
                return t.indexOf("number") > -1 && type === "string" && !isNaN(Number(value));

            }
        },
        error: function(p = {}){
            const t = p.value;
            if (t && t.length) {
                return "invalid type, please specify a value of this type [" + t.toString() + "]";
            }else{
                return "bad configuration [" + t.toString() + "]";
            }
        },
    },
    color: {
        validation: function(p = {}){
            const t = p.value || [];
            return function(value){
                const type = typeof value;
                if (!t) return false;
                if (type === "string"){
                    if (t && t.indexOf("rgba") > -1) {
                        if (value && value.match("rgba") && value.slice(0, 5) === "rgba(" && value.slice(-1) === ")") {
                            const n = value.slice(5, -1).replace(/ /g, "").split(",");
                            const r = Number(n[0]);
                            const g = Number(n[1]);
                            const b = Number(n[2]);
                            const a = Number(n[3]);
                            if (r >= 0 && r <= 255 &&
                                g >= 0 && g <= 255 &&
                                b >= 0 && b <= 255 &&
                                a >= 0 && a <= 1) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            }
        },
        error: function(p = {}){
            const t = p.value;
            if (t && t.length) {
                return "invalid type, please specify a value of this type [" + t.toString() + "]";
            } else {
                return "bad configuration [" + "missing type" + "]";
            }
        },
    },
    arrayIn: {
        validation: function(p = {}){
            const a = p.value || [];
            return function(value){
                const type = typeof value;
                if (type === "object") {
                    if (value && value.length && a && a.length) {
                        let foundinvalid = false;
                        value.map(function (v) {
                            if (a.indexOf(v) === -1) foundinvalid = true;
                        })
                        if (foundinvalid) return false;
                    }
                } else {
                    if (a && a.length && a.indexOf(value) === -1) return false;
                }
                return true;
            }
        },
        error: function(p = {}){
            const a = p.value;
            if (a && a.length) {
                return "invalid type, please choose one of them [" + a.toString() + "]";
            }else{
                return "bad configuration [" + a.toString() + "]";
            }
        },
    },
    parsable: {
        validation: function(p = {}){
            const enableEmpty = p["enableEmpty"];
            return function(value){
                try {
                    if (enableEmpty && !value) return true;
                    // noinspection JSUnusedLocalSymbols
                    const p = JSON.parse(value);
                    return true;
                } catch(err) {
                    return false;
                }
            }
        },
        error: function(){
            return "invalid type, please add a parsable string";
        },
    },
}

export default (p) => {

    const {schema, props} = p;
    const {value} = props;

    const sv = (typeof schema.validations === "function") ? schema.validations(p) : schema.validations;

    if (sv?.length){
        const errors = sv.map((validation)=>{
            const {name = "", ...rest} = validation;
            if (name && validations[name]){
                const isValidFunction = validations[name].validation({...rest})
                const isValid = isValidFunction(value);
                if (!isValid){
                    return validations[name].error({...rest})
                }
            }
            return "";
        }).filter((r)=>r);
        return (errors.length) ? {isValid: false, errors} : {isValid: true};
    }
    return {isValid: true};
}
