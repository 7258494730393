import React from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AdminPanelSettings from "@material-ui/icons/AdminPanelSettings";
import Logo from "configurator-studio-arkitekter-components/dist/common/components/Logo";
import Avatar from "../Avatar/me";
import {runPostTypesConfigSync} from "../../postTypes";

function getMenu(props = {}) {

    const {appContext} = props;
    const {menus, routes, /*userStatusManager*/} = appContext;

    async function onClickBefore() {
        if (appContext.template?.actions){
            appContext.template.actions.storeDrawerScrollTop();
        }
        if (appContext.template?.actions){
            await appContext.template.actions.drawerCloseNarrow();
        }
    }

    return [
        {
            name: menus.homeMenu,
            href: "/",
            Icon: function (props) {
                return (
                  <div style={{height:"24px"}}><Logo  {...props} /></div>
                )
            },
            onClickBefore
        },
        {
            name: menus.loginMenu,
            href: routes.accountRoute + "/login",
            role: function (p) {
                return !(p?.user?._id);
            },
            Icon: AccountCircleIcon,
            onClickBefore
        },
        {
            name: menus.dashboardMenu,
            href: function (p) {
                return (p?.user?._id) ? routes.userRoute + "/" + p.user._id : routes.userRoute;
            },
            role: function (p) {
                return p?.user?._id;
            },
            Icon: function (props) {
                return (
                  <Avatar size={"small"} {...props}/>
                )
            },
            onClickBefore
        },
        {divider: true, role: (p)=>p.user && p.user._status_isFeatured},
        {
            name: "Admin",
            role: function (p) {
                return p.user && p.user._status_isFeatured;
            },
            items: [
                ...runPostTypesConfigSync({action:"adminMenu", p: props}).filter((m)=>m).map((menu)=>{
                    menu.items.forEach((item)=>{
                        item.onClickBefore = onClickBefore;
                    });
                    return menu;
                })
            ],
            onClickBefore,
            Icon: AdminPanelSettings
        },
        {divider: true},
        {
            name: menus.logoutMenu,
            href: routes.accountRoute + "/logout",
            role: function (p) {
                return p?.user?._id;
            },
            Icon: ExitToAppIcon,
            onClickBefore
        },
    ];

}

export default getMenu;
