import React, {useContext} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import AppContext from "../../App/context";
import {withMaterialStyles} from "../../Template/withMaterial";
import Form from "../../Form";

import materialStyle from "./materialStyle";
import style from "./style.css";
import AccountContext from "../context";
import capitalize from "../../../utils/capitalize";
import {postTypesConfig} from "../../../postTypes";

function ChangeData(/*props*/) {

    const accountContext = useContext(AccountContext);
    const {user, name} = accountContext;

    const N = capitalize(name);

    const appContext = useContext(AppContext);
    const context = useContext(WappContext);
    const utils = getUtils(context);
    //const {materialStyle} = props;

    const {wapp} = context;

    async function onSubmit(e, formData) {
        return await utils.sendRequest({requestName: name+"Save", args: formData, redirect: null });
    }

    let formDataFromResolvers = {};
    try {
        formDataFromResolvers = utils.getGlobalState("res.graphql.mutation."+name+"Save.formData");
    } catch (e){}

    const formData = {
        ...formDataFromResolvers,
        submit: {
            label: appContext.labels["save"+N+"SubmitLabel"]
        }
    };

    if (user?._id){
        formData._id.value = user;
        formData._id.disabled = true;
        formData._id.refPostType = name;
    }

    if (user?._id) {
        Object.keys(formData).forEach(function (key) {
            if (key.startsWith("record.")) {
                const ka = key.split(".");
                let value = user;
                ka.forEach(function (nk) {
                    if (nk !== "record"){
                        if ((value && value[nk]) || (value && value[nk] === 0)  || (value && value[nk] === false)){
                            value = value[nk];
                        } else {
                            value = null;
                        }
                    }
                });
                if (value || value === 0 || value === false){
                    formData[key].value = value;
                }
            }
        });
    }

    Object.keys(formData).forEach((key)=>{
        if ((formData[key].componentName?.startsWith("Posts") && formData[key].enableNew) || (formData[key].schemaType === "MongoID" && formData[key].enableNew) ){
            const refPostType = formData[key].refPostType;
            formData[key].NewComponent = postTypesConfig[refPostType] && postTypesConfig[refPostType].getPages ? postTypesConfig[refPostType].getPages().new : null;
        }
    });


    wapp.styles.use(style);

    return (
        <Form
            formData={formData}
            onSubmit={onSubmit}
            successMessage={
                appContext.messages.changeDataSuccessMessage
            }
        />
    )
}

const WappComponent = withWapp(ChangeData);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
