import React, {useContext, useRef} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import AppContext from "../../../components/App/context";
import {withMaterialStyles} from "../../../components/Template/withMaterial";
import PostContext from "../../../components/Post/context";
import Form, {defaultComponents} from "../../../components/Form";
import SchemaForm from "../../../components/SchemaForm";
import defaultAppData from "configurator-studio-arkitekter-app/dist/common/defaultAppData";

import materialStyle from "./materialStyle";
import style from "./style.css";

import getSchemaForSchemaForm from "../Edit/schema";
import capitalize from "../../../utils/capitalize";
import {postTypesConfig} from "../../index";

function getFormDataForParentForm({post, utils, name, appContext}) {

    const N = capitalize(name);

    let formDataFromResolvers = {};
    try {
        formDataFromResolvers = utils.getGlobalState("res.graphql.mutation."+name+"New.formData");
    } catch (e){}

    const formData = {
        ...formDataFromResolvers,
        submit: {
            label: appContext.labels["new"+N+"SubmitLabel"]
        }
    };

    if (formData["record.title"]) {
        formData["record.title"].hidden = true;
        formData["record.title"].disabled = true;
    }

    if (post?._id) {
        Object.keys(formData).forEach(function (key) {
            if (key.startsWith("record.")) {
                const ka = key.split(".");
                let value = post;
                ka.forEach(function (nk) {
                    if (nk !== "record"){
                        if ((value && value[nk]) || (value && value[nk] === 0)){
                            value = value[nk];
                        } else {
                            value = null;
                        }
                    }
                });
                if (value || value === 0){
                    formData[key].value = value;
                }
            }
        })
    }

    return formData;

}

function NewWithoutPost() {

    const postContext = useContext(PostContext);
    const {post, name, parentRoute} = postContext;

    const N = capitalize(name);

    const context = useContext(WappContext);
    const {wapp} = context;
    const appContext = useContext(AppContext);
    const utils = getUtils(context);

    wapp.styles.use(style);

    const schemaFormForm = useRef();

    let postContent = {};
    try {
        postContent = JSON.parse(post.content)
    } catch (e){}

    const formSettingsForParent = getFormDataForParentForm({post, utils, name, appContext});

    const schemaForSchemaForm = getSchemaForSchemaForm({data: postContent});

    formSettingsForParent["record.content"].componentName = "SchemaForm";
    formSettingsForParent["record.content"].data = postContent;
    formSettingsForParent["record.content"].schema = schemaForSchemaForm;

    Object.keys(formSettingsForParent).forEach((key)=>{
        if (formSettingsForParent[key].componentName?.startsWith("Posts") && formSettingsForParent[key].enableNew || formSettingsForParent[key].schemaType === "MongoID" && formSettingsForParent[key].enableNew ){
            const refPostType = formSettingsForParent[key].refPostType;
            formSettingsForParent[key].NewComponent = postTypesConfig[refPostType] && postTypesConfig[refPostType].getPages ? postTypesConfig[refPostType].getPages().new : null;
        }
    });

    async function onSubmit(e, data) {

        const isValid = schemaFormForm.current.isValid();
        const schemaFieldResponse = await schemaFormForm.current.onSubmit(e);
        const schemaFieldData = schemaFieldResponse.data;
        const schemaFieldError = schemaFieldResponse.error;

        if (schemaFieldError || !isValid) {
            return;
        }

        const languages = schemaFieldData.settings?.basic_settings?.languages || ["en_us"];

        const args = {
            ...data,
            record: {
                ...data.record,
                content: JSON.stringify({
                    ...schemaFieldData
                }),
                title: schemaFieldData.settings?.basic_settings?.name[languages[0]] || data.record.title || "My configurator"
            }
        }

        return await utils.sendRequest({requestName: name+"New", args, redirect: {pathname: parentRoute+"/:_id", search:"", hash:""}, timeOut:1000 });

    }

    const customFormComponents = {
        SchemaForm: {
            Component: SchemaForm,
            props: {
                schema: {},
                data: {},
                ref: (e)=>{schemaFormForm.current = e;},
                storageName: (post?._id) ? "SchemaForm_"+post._id : "SchemaForm",
                FormComponent: (props)=> <div className={props.className}>{props.children}</div>,
                onSubmit: async (e, {data, error})=>{
                    return {data, error};
                },
                additionalProperties: {
                    generateSchema: ({schemaFormContext}) => {
                        return getSchemaForSchemaForm({data: schemaFormContext.rootData});
                    }
                }
            }
        },
    }

    return (
        <Form
            formData={formSettingsForParent}
            onSubmit={onSubmit}
            successMessage={
                appContext.messages["new"+N+"SuccessMessage"]
            }
            components={{
                ...defaultComponents,
                ...customFormComponents
            }}
            initialState={(state) =>{
                return {
                    ...state,
                    formData: {
                        ...state.formData,
                        ["record.content"]: {
                            ...state.formData["record.content"],
                            schema: schemaForSchemaForm
                        }
                    }
                }
            }}
        />
    )
}

function New(props) {
    const postContext = useContext(PostContext);

    const copyDefaultAppData = JSON.parse(JSON.stringify(defaultAppData));
    const languages = copyDefaultAppData.settings?.basic_settings?.languages || ["en_us"];

    if (copyDefaultAppData.settings?.basic_settings?.name){
        languages.forEach((lang)=> {
            copyDefaultAppData.settings.basic_settings.name[lang] = "My configurator"
        })
    }

    return (
        <PostContext.Provider value={{...postContext, post: {title: "My configurator", content: JSON.stringify(copyDefaultAppData)}}}>
            <NewWithoutPost {...props}/>
        </PostContext.Provider>
    )
}

const WappComponent = withWapp(New);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
