export default function makeMaterialStyle(theme) {
    return {
        root: {
            "& $listItem": {
                "&:hover": {
                    backgroundColor: theme.palette.action.disabledBackground,
                },
                color: theme.palette.text.primary
            },
            "& $listItemIcon": {
                color: theme.palette.text.primary
            },
            "& $listItemIconDisabled": {
                color: theme.palette.text.disabled
            },
            "& $cardItem": {
                "&:hover": {},
                color: theme.palette.text.primary
            },
            "& $cardItemIcon": {
                color: theme.palette.text.primary
            },
            "& $tableItem": {
                "&:hover": {
                    backgroundColor: theme.palette.action.disabledBackground,
                },
                color: theme.palette.text.primary
            }
        },
        tableItem: {},
        listItem: {},
        listItemIcon: {},
        listItemIconDisabled: {},
        cardItem: {},
        cardItemIcon: {},
        cardItemIconDisabled: {},
    }
}
