import React, {useContext, useState, useEffect, useRef, useMemo} from "react";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";
import {WappContext} from "wapplr-react/dist/common/Wapp";

import messages from "../../config/constants/messages";
import labels from "../../config/constants/labels";
import titles from "../../config/constants/titles";
import routes from "../../config/constants/routes";
import menus from "../../config/constants/menus";

import {runPostTypesConfigSync} from "../../postTypes";

import {withMaterialTheme} from "../Template/withMaterial";
import Template from "../Template";
import Logo, {SA} from "configurator-studio-arkitekter-components/dist/common/components/Logo";

import NotFound from "../NotFound";
import AppContext from "../App/context";
//import ExternalContext from "./externalContext";

import Cookies from "../Cookies";
import Account from "../Account";
import Home from "../Home";

import style from "./style.css";
import getMenu from "./menu";
import getTopMenu from "./topMenu";
import getFooterMenu from "./footerMenu";

import {materialTheme, materialMediaQuery} from "./materialTheme";

import {storage as defaultLocalStorage} from "configurator-studio-arkitekter-components/dist/common/utils/localStorage";
import {storage as defaultMemoStorage} from "configurator-studio-arkitekter-components/dist/common/utils/memoStorage";

export default function App(props) {

    const context = useContext(WappContext);
    //const externalContext = useContext(ExternalContext);
    //console.log("External settings", externalContext);

    const {wapp, res} = context;
    const utils = getUtils(context);
    const {subscribe, userPostTypeName = "user"} = props;

    wapp.styles.use(style);

    const ThemedTemplate = useMemo(()=> withMaterialTheme({theme: materialTheme, mediaQuery: materialMediaQuery, classNamePrefix: wapp.globals.WAPP}, Template), [wapp.globals.WAPP]);

    const container = useRef();

    const [url, setUrl] = useState(utils.getRequestUrl());
    const [position] = useState(wapp.globals.NAME === wapp.globals.RUN ? "fixed" : "sticky");
    const [fullscreen] = useState(wapp.globals.NAME === wapp.globals.RUN);

    const storageName = wapp.globals.NAME;
    const template = useMemo(()=>{return {}}, []);

    useEffect(function (){

        async function onLocationChange(newUrl){
            if (url !== newUrl){
                await setUrl(newUrl);
            }
        }

        const unsub = subscribe.locationChange(onLocationChange);
        return function useUnsubscribe(){
            unsub();
        }
    }, [subscribe, url, template.actions]);

    const storage = function (data, memo) {
        if (memo){
            return defaultMemoStorage((data) ? data : {}, storageName);
        }
        return defaultLocalStorage(data, storageName);
    };

    const userStatusManager = wapp.getTargetObject().postTypes.findPostType({name: userPostTypeName}).statusManager;
    const route = res.wappResponse.route;
    const requestPath = route.requestPath;

    const appContext = {messages, labels, titles, routes, menus, userStatusManager, storage, template};

    const PostTypesComponent = runPostTypesConfigSync({action:"getComponent", p:{context, appContext}}).filter((C)=> !!(C))[0];

    return (
        <AppContext.Provider value={appContext}>
            <div
                ref={container}
                className={style.app}
            >
                <ThemedTemplate
                    position={position}
                    fullscreen={fullscreen}
                    getMenu={getMenu}
                    getTopMenu={getTopMenu}
                    getFooterMenu={getFooterMenu}
                    Logo={Logo}
                    FooterLogo={SA}
                    effect={(p)=>{template.actions = p?.actions}}
                >
                    {
                        (requestPath === "/") ?
                          <Home />
                          :
                          (requestPath.startsWith(routes.accountRoute)) ?
                            <Account />
                            :
                            (PostTypesComponent) ?
                              <PostTypesComponent />
                              :
                              <NotFound />
                    }
                    <Cookies />
                </ThemedTemplate>
            </div>
        </AppContext.Provider>
    );
}
