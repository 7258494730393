import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";

export default function getConstants(p = {}) {

    const name = "email";
    const N = capitalize(name);

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    });

    return {
        labels: {
            ...labels
        },
        menus: {
            ...menus,
            ["new"+N+"Menu"]: "Create a system email"
        },
        messages: {
            ...messages
        },
        routes: {
            ...routes,
        },
        titles: {
            ...titles,
        }
    }
}
