import React, {useContext} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import AppContext from "../../App/context";
import {withMaterialStyles} from "../../Template/withMaterial";
import Form from "../../Form";
import PostContext from "../../Post/context";

import materialStyle from "./materialStyle";
import style from "./style.css";
import capitalize from "../../../utils/capitalize";
import {postTypesConfig} from "../../../postTypes";

function Edit(props) {

    const {formProps = {}, requestProps = {}} = props;

    const postContext = useContext(PostContext);
    const {name, post, parentRoute, /*statusManager*/} = postContext;

    const N = capitalize(name);

    const appContext = useContext(AppContext);
    const context = useContext(WappContext);
    const utils = getUtils(context);
    //const {materialStyle} = props;

    const {wapp, req} = context;

    const query = req.wappRequest.query;

    async function onSubmit(e, formData) {

        const initialState = window[wapp.config.appStateName] || {req:{timestamp: Date.now()}};
        const firstRender = (utils.getGlobalState("req.timestamp") === initialState.req.timestamp);

        const redirect = query.redirect ?
            {pathname: query.redirect} :
            !firstRender ?
                null :
                {pathname:  parentRoute+"/"+post._id, search:"", hash:""};

        const go = (redirect) ? 0 : -1;

        return await utils.sendRequest({requestName: name+"Save", args: formData, redirect, go, timeOut:1000, ...requestProps });

    }

    let formDataFromResolvers = {};
    try {
        formDataFromResolvers = utils.getGlobalState("res.graphql.mutation."+name+"Save.formData");
    } catch (e){
        console.log(e)
    }

    const isNotDeleted = post && post._status_isNotDeleted;

    const formData = {
        ...formDataFromResolvers,
        submit: {
            label: (isNotDeleted) ? appContext.labels["save"+N+"SubmitLabel"] : appContext.labels["restore"+N+"SubmitLabel"]
        }
    };

    if (post?._id && formData._id){
        formData._id.value = post;
        formData._id.disabled = true;
        formData._id.refPostType = name;
    }

    if (post?._id) {
        Object.keys(formData).forEach(function (key) {
            if (key.startsWith("record.")) {
                const ka = key.split(".");
                let value = post;
                ka.forEach(function (nk) {
                    if (nk !== "record"){
                        if ((value && value[nk]) || (value && value[nk] === 0)  || (value && value[nk] === false)){
                            value = value[nk];
                        } else {
                            value = null;
                        }
                    }
                });
                if (value || value === 0 || value === false){
                    formData[key].value = value;
                }
            }
        });
    }

    Object.keys(formData).forEach((key)=>{
        if ((formData[key].componentName?.startsWith("Posts") && formData[key].enableNew) || (formData[key].schemaType === "MongoID" && formData[key].enableNew) ){
            const refPostType = formData[key].refPostType;
            formData[key].NewComponent = postTypesConfig[refPostType] && postTypesConfig[refPostType].getPages ? postTypesConfig[refPostType].getPages().new : null;
        }
    });

    wapp.styles.use(style);

    return (
        <div className={style.edit}>
            <Form
                formData={formData}
                onSubmit={onSubmit}
                successMessage={
                    appContext.messages["save"+N+"SuccessMessage"]
                }
                {...(typeof formProps == "function") ? formProps({formData}) : formProps}
            />
        </div>
    )
}

const WappComponent = withWapp(Edit);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
