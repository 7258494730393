// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HJmUh{}\r\n\r\n.MGPKf{\r\n    border-style: solid;\r\n    border-width: 1px;\r\n    border-color: var(--border-color);\r\n    border-radius: 5px;\r\n    overflow: hidden;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": "HJmUh",
	"content": "MGPKf"
};
module.exports = ___CSS_LOADER_EXPORT___;
