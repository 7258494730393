import App from "../../components/App";
import capitalize from "../../utils/capitalize";

import PersonIcon from "@material-ui/icons/Person";
import PersonOutlined from "@material-ui/icons/PersonOutlined";

import {runPostTypesConfig} from "../index";
import defaultPostTypeConfig from "../post";
import collectRouteParams from "../../utils/collectRouteParams";

export default function setContents({wapp, routes, titles, name="user", getTitle=function ({title}){return title;}}) {

    const n = name;
    const ns = (n.endsWith("y")) ? n.slice(0,-1)+"ies" : n+"s";
    const N = capitalize(n);

    let reqUserForPost = null;

    function getPostTitle(p) {
        const wappResponse = p.res.wappResponse;
        const post = wappResponse.store.getState("res.responses."+n+"FindById");
        const route = wappResponse.route;
        const {params} = route;
        let title = titles[n+"Title"];
        if (post && params._id === post._id && post.title){
            switch (params.page) {
                case "edit":
                    title = titles["edit"+N+"Title"] + " | " + post.title;
                    break;
                case "posts":
                    switch (params.pageType) {
                        case "deleted":
                            title = titles[n+"DeletedPostsTitle"] + " | " + post.title;
                            break;
                        default:
                            title = titles[n+"PostsTitle"] + " | " + post.title
                    }
                    break;
                default:
                    title = post.title;
            }
        }
        return getTitle({...p, title})
    }

    wapp.contents.add({
        [n]: {
            render: App,
            renderType: "react",
            title: getPostTitle,
            description: function (p) {
                const {wapp, req, res} = p;
                const wappResponse = res.wappResponse;
                const post = wappResponse.store.getState("res.responses."+n+"FindById");
                if (post?._id && post?.title){
                    return titles["get"+N+"Description"](post.title);
                }
                const config = wapp.getTargetObject().config;
                const {description} = config;
                return (description) ? description : getPostTitle({wapp, req, res}).split(" | ")[0];
            },
            request: async function ({wapp, req, res}) {
                const wappResponse = res.wappResponse;
                const post = wappResponse.store.getState("res.responses."+n+"FindById");
                const route = wappResponse.route;
                const {params} = route;
                const {_id} = params;

                const statusManager = wapp.getTargetObject().postTypes.findPostType({name: n}).statusManager;

                await runPostTypesConfig({action: "requestFor"+N+"Page", p:{wapp, req, res, statusManager}});

                if ((_id && !post) ||
                    (_id && post && post._id !== _id) ||
                    (_id && reqUserForPost?._id !== req.wappRequest.user?._id)) {
                    reqUserForPost = {_id: req.wappRequest.user?._id};
                    return await wapp.requests.send({requestName: n+"FindById", args: {_id: _id}, req, res});
                }
            }
        },
        [ns]: {
            render: App,
            renderType: "react",
            title: (p)=>wapp.contents.get("posts").title({...p, name}),
            description: (p)=>wapp.contents.get("posts").description({...p, name}),
            request: async function ({wapp, req, res}) {

                const wappResponse = res.wappResponse;
                const wappRequest = req.wappRequest;
                const route = wappResponse.route;
                const {params} = route;
                const query = wappRequest.query;

                const listData = wappResponse.store.getState("res.graphql.query."+name+"FindMany.listData") || {};
                const listDataSort = listData.sort || [];

                if (query.search) {
                    listDataSort.push({key: "TEXTSCORE"})
                }

                const defaultSort = listDataSort[0]?.key || "";

                let sort = (params.sort && listDataSort && listDataSort.map((p)=>p.key).find((key)=>key === params.sort)) || defaultSort;

                if (sort === "TEXTSCORE") {
                    sort = "";
                }

                const page = params.page;
                const pagination = !isNaN(Number(params.pagination)) && Number(params.pagination) ? Number(params.pagination) : 1;

                const perPageFormData = listData.perPage;
                const limitPerPage = perPageFormData.limit || 100;
                const defaultPerPage = perPageFormData.default || 20;
                const perPage = (params.limit && !isNaN(Number(params.limit)) && Number(params.limit) <= limitPerPage && Number(params.limit) > 1) ? Number(params.limit) : defaultPerPage;

                const postStatusManager = wapp.getTargetObject().postTypes.findPostType({name: n}).statusManager;

                const status =
                    (page === "banned") ?
                        {gt: postStatusManager.getBannedStatus() - 1, lt: postStatusManager.getDeletedStatus()} :
                        (page === "deleted") ?
                            {gt: postStatusManager.getDeletedStatus() - 1, lt: postStatusManager.getDefaultStatus()} :
                            (page === "missingdata") ?
                                {gt: postStatusManager.getDefaultStatus() - 1, lt: postStatusManager.getMinStatus()} :
                                (page === "protected") ?
                                    {gt: postStatusManager.getFeaturedStatus() - 1} :
                                        {gt: postStatusManager.getMinStatus() - 1};

                await wapp.requests.send({
                    requestName: n+"FindMany",
                    args: {
                        filter: {
                            ...(query.search) ? {search: query.search} : {},
                            _operators:{
                                _status: status,
                            }
                        },
                        ...(sort) ? {sort} : {},
                        page: pagination,
                        perPage
                    },
                    req,
                    res
                });

            }
        }
    });

    const paths = collectRouteParams({
        startRoutes: [
            {path: routes[n+"Route"]+"/:_id/:page", contentName: n},
            {path: routes[n+"Route"]+"/:_id/:page/:pageType", contentName: n},
            {path: routes[ns+"Route"], contentName: ns},
            {path: routes[ns+"Route"]+"/:page", contentName: ns},
        ],
        params: [
            "/page/:pagination",
            "/sort/:sort",
            "/limit/:limit",
            "/tags/:tags"
        ]
    });

    wapp.router.add([

        {path: routes[n+"Route"], contentName: n},
        {path: routes[n+"Route"]+"/:_id", contentName: n},

        ...paths

    ]);

    return {
        name: n
    }

}

export async function requestForUserPage() {
    return null;
}

export function adminMenu(p) {
    const name = "user";
    const menu = defaultPostTypeConfig.adminMenu({...p, name});

    const {routes} = p.appContext;

    menu.items = menu.items.filter((item)=> {
        if (item.href === routes[name+"Route"] + "/new"){
            return false;
        }
        return item.divider || (typeof item.name === "function" && item.name()) || (typeof item.name !== "function" && item.name);
    });
    menu.Icon = PersonIcon;
    menu.order = 4;
    menu.items[0].Icon = PersonOutlined;
    return menu;
}
