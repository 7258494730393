"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.materialTheme = exports.materialMediaQuery = void 0;

var materialTheme = function materialTheme() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _p$mediaQuery = p.mediaQuery,
      mediaQuery = _p$mediaQuery === void 0 ? {} : _p$mediaQuery;
  var prefersDarkMode = mediaQuery["(prefers-color-scheme: dark)"];
  var printMode = mediaQuery["print"];
  var dark = !!(prefersDarkMode && !printMode);
  return {
    palette: {
      primary: {
        main: dark ? "#ffffff" : "#000000"
      },
      secondary: {
        main: dark ? "#0037a1" : "#0037a1"
      },
      background: {
        paper: dark ? "#303338" : "#ffffff"
      },
      mode: dark ? "dark" : "light"
    }
  };
};

exports.materialTheme = materialTheme;
var materialMediaQuery = ["(prefers-color-scheme: dark)", "print"];
exports.materialMediaQuery = materialMediaQuery;