import {runPostTypesConfigSync} from "../../postTypes";

const messages = {
    notFoundNotAvailable: "This content is not available",
    notFoundLoginText: "Log in to your account because there are pages that can only be seen with permission",
    thereAreNoEntries:"There are no entries",
    cookieShortDesc: "Only cookies that are necessary for the operation of the site are used. By using the site, you consent to this.",
    ...runPostTypesConfigSync({action:"getConstants", rKey:"messages"}).reduce((a, v) => {return {...a, ...v}}, {})
};

export default messages;
