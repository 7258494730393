// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".acWUY{\n    width: 100%;\n    position: relative;\n    overflow: hidden;\n}\n\n._9T2aN {\n    border-spacing: 0;\n    margin: 0;\n    padding: 8px;\n}\n\n._9T2aN tr {\n\n}\n\n._9T2aN tr:last-child {\n\n}\n\n._9T2aN th {\n    vertical-align: top;\n    text-align: left;\n    padding: 6px 0;\n    line-height: normal;\n}\n\n._9T2aN td {\n    vertical-align: inherit;\n    text-align: left;\n    padding: 6px 0 6px 16px;\n}\n\n._9T2aN .utgL1, ._9T2aN .z9eLH {\n    width: 100%;\n    display: grid;\n}\n\n.DUNJU {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n}\n\n.DUNJU .a\\+n9W {}\n\n.DUNJU .a\\+n9W > span {\n    word-break: keep-all;\n    white-space: nowrap;\n    -o-text-overflow: ellipsis;\n       text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n.DUNJU .vUtKc {\n    margin-right: 8px;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n}\n\n.\\+fPBh {\n    word-break: keep-all;\n    white-space: nowrap;\n    -o-text-overflow: ellipsis;\n       text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n.\\+fPBh.eSH8C {\n    word-break: normal;\n    white-space: normal;\n}\n\n.\\+fPBh.eSH8C.j1YCJ {\n    display: -webkit-box;\n    /*noinspection CssUnknownProperty*/\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n}\n\n.\\+fPBh ._5vetD{\n    margin-left: 3px;\n}\n\n.z4HV4 {\n    word-break: keep-all;\n    white-space: nowrap;\n    -o-text-overflow: ellipsis;\n       text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n.ieQ59 {\n    word-break: keep-all;\n    white-space: nowrap;\n    -o-text-overflow: ellipsis;\n       text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n.zVgBG {\n    display: -ms-flexbox;\n    display: flex;\n}\n\n.zVgBG svg {\n\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "acWUY",
	"table": "_9T2aN",
	"thInner": "utgL1",
	"tdInner": "z9eLH",
	"label": "DUNJU",
	"labelText": "a+n9W",
	"icon": "vUtKc",
	"textField": "+fPBh",
	"multiline": "eSH8C",
	"maxRows": "j1YCJ",
	"textFieldEndFix": "_5vetD",
	"status": "z4HV4",
	"date": "ieQ59",
	"checkbox": "zVgBG"
};
module.exports = ___CSS_LOADER_EXPORT___;
