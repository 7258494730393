"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var routes = {
  modelsRoute: "/models",
  collectionsRoute: "/collections",
  summaryRoute: "/summary"
};
var _default = routes;
exports["default"] = _default;