"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var menus = {
  startConfiguratorMenu: "Start configurator",
  descriptionMenu: "Description",
  modelsMenu: "Model",
  collectionsMenu: "Collection",
  summaryMenu: "Summary",
  collectionsMenuCustomSpan: "Custom"
};
var _default = menus;
exports["default"] = _default;