// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tt0xL{}\n\n.Lh50x {}\n\n.GTtEx{}\n\n.GTtEx > div{\n    width: 100%;\n    margin-bottom: 24px;\n}\n\nbutton.I-1VJ {\n    margin-left: auto;\n    margin-right: auto;\n    display: block;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settings": "tt0xL",
	"menu": "Lh50x",
	"form": "GTtEx",
	"logoutSubmit": "I-1VJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
