// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PRq-R {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n    -ms-flex-pack: center;\n        justify-content: center;\n    width: 100%;\n    height: 100%;\n    min-height: inherit;\n}\n\n.PRq-R .Kt7iv {\n    -webkit-box-shadow: none;\n            box-shadow: none;\n    background-color: transparent;\n}\n\n.q0lvE {\n    margin-bottom: 128px;\n    padding-right: 0;\n    padding-left: 0;\n}\n\n.SzEuQ {\n    width: 100%;\n    overflow: hidden;\n}\n\n.SzEuQ.U1ojH {\n    padding-top: 24px;\n}\n\n.ELwNc {\n    overflow: hidden;\n    -o-text-overflow: ellipsis;\n       text-overflow: ellipsis;\n}\n\n.HQZai {\n    overflow: hidden;\n    -o-text-overflow: ellipsis;\n       text-overflow: ellipsis;\n    margin-bottom: 12px;\n}\n\n.VXRlS {\n    min-height: 128px;\n    padding: 32px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notFound": "PRq-R",
	"appBar": "Kt7iv",
	"container": "q0lvE",
	"titleContainer": "SzEuQ",
	"withSubtitle": "U1ojH",
	"title": "ELwNc",
	"subtitle": "HQZai",
	"content": "VXRlS"
};
module.exports = ___CSS_LOADER_EXPORT___;
