import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";

export default function getConstants(p = {}) {

    const name = "file";
    const N = capitalize(name);
    const Ns = (N.endsWith("y")) ? N.slice(0,-1)+"ies" : N+"s";

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    });

    return {
        labels: {
            ...labels,
            ["cancelAbortUpload"+Ns+"Text"]: "Cancel",
            ["abortUpload"+Ns+"Text"]: "Abort",
        },
        menus: {
            ...menus,
            ["new"+N+"Menu"]: "Upload files"
        },
        messages: {
            ...messages,
            ["new"+Ns+"SuccessMessage"]: "Files uploaded",
            ["abortUpload"+Ns+"Question"]: "Do you want to abort upload?",
            ["abortUpload"+Ns+"Success"]: "Upload aborted",
            ["new"+Ns+"ClientErrorMessage"]: "Error occurs while loading an external file, please try update the page",
        },
        routes: {
            ...routes,
        },
        titles: {
            ...titles,
            ["dialogAbortUpload"+Ns+"Title"]: "Abort upload",
        }
    }
}
