"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getConfiguration = getConfiguration;
exports.getDataAndInitialConfiguration = getDataAndInitialConfiguration;
exports.getDayPeriodImage = getDayPeriodImage;
exports.getInitialConfiguration = getInitialConfiguration;
exports.getIsAuthorOrAdmin = getIsAuthorOrAdmin;
exports.getPrice = getPrice;
exports.getQueryString = getQueryString;
exports.setConfiguration = setConfiguration;
exports.translate = translate;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));

var _getUtils = _interopRequireDefault(require("wapplr-react/dist/common/Wapp/getUtils"));

function translate(a) {
  var selectedLang = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "lang_1";

  if (typeof a == "string") {
    return a;
  }

  if (typeof a == "object" && a[selectedLang]) {
    return a[selectedLang];
  }

  if (typeof a == "object") {
    return a[Object.keys(a)[0]] || "";
  }

  return "";
}

function getPrice(a, data) {
  var _data$settings, _data$settings$basic_, _data$settings2, _data$settings2$basic, _data$settings3, _data$settings3$basic;

  var selectedLang = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "lang_1";
  var languages = ((_data$settings = data.settings) === null || _data$settings === void 0 ? void 0 : (_data$settings$basic_ = _data$settings.basic_settings) === null || _data$settings$basic_ === void 0 ? void 0 : _data$settings$basic_.languages) || [];
  var defaultCurrencyValue = ((_data$settings2 = data.settings) === null || _data$settings2 === void 0 ? void 0 : (_data$settings2$basic = _data$settings2.basic_settings) === null || _data$settings2$basic === void 0 ? void 0 : _data$settings2$basic.currency_value) && data.settings.basic_settings.currency_value[languages[0]] || 1;
  var currencyValue = ((_data$settings3 = data.settings) === null || _data$settings3 === void 0 ? void 0 : (_data$settings3$basic = _data$settings3.basic_settings) === null || _data$settings3$basic === void 0 ? void 0 : _data$settings3$basic.currency_value) && data.settings.basic_settings.currency_value[selectedLang] || 1;

  if (typeof a == "number") {
    return a;
  }

  if (typeof a == "object" && typeof a[selectedLang] == "number") {
    return a[selectedLang];
  }

  if (typeof a == "object" && typeof a[selectedLang] == "undefined" && typeof a[languages[0]] === "number" || typeof a == "object" && a[selectedLang] === "" && typeof a[languages[0]] === "number") {
    if (a[languages[0]]) {
      return Math.round(a[languages[0]] * (currencyValue / defaultCurrencyValue));
    } else {
      return 0;
    }
  }

  return 0;
}

function getDayPeriodImage(a) {
  var selectedDayPeriod = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "day";

  if (typeof a == "string") {
    return a;
  }

  if (typeof a == "object" && a["image_url_" + selectedDayPeriod]) {
    return a["image_url_" + selectedDayPeriod];
  }

  if (typeof a == "object" && a["image_url"]) {
    return a["image_url"];
  }

  return "";
}

function setConfiguration(_ref) {
  var newConfiguration = _ref.newConfiguration,
      configuration = _ref.configuration,
      data = _ref.data,
      isAuthorOrAdmin = _ref.isAuthorOrAdmin,
      onChange = _ref.onChange,
      _setConfiguration = _ref._setConfiguration,
      storage = _ref.storage;
  var validNewConfiguration = getConfiguration({
    data: data,
    savedConfiguration: newConfiguration,
    isAuthorOrAdmin: isAuthorOrAdmin
  });

  if (!validNewConfiguration.error) {
    try {
      if (JSON.stringify(validNewConfiguration) !== JSON.stringify(configuration)) {
        storage({
          configuration: validNewConfiguration
        });

        _setConfiguration(JSON.parse(JSON.stringify(validNewConfiguration)));

        if (onChange) {
          onChange(validNewConfiguration);
        }
      } else {
        console.log("[CSAA] It tried update the configuration but no changes...");
      }
    } catch (e) {
      console.error({
        message: "An error occurred while saving ...",
        code: 1004,
        originalError: e
      });
    }
  }

  if (validNewConfiguration.error) {
    if (validNewConfiguration.error.code < 1100) {
      if (isAuthorOrAdmin) {
        console.error(validNewConfiguration.error);
      }
    } else {
      console.warn(validNewConfiguration.error);
    }
  }
}

function getConfiguration() {
  var _data$settings4, _data$settings4$basic;

  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var data = p.data,
      _p$savedConfiguration = p.savedConfiguration,
      savedConfiguration = _p$savedConfiguration === void 0 ? {} : _p$savedConfiguration,
      isAuthorOrAdmin = p.isAuthorOrAdmin;
  var selectedModel = savedConfiguration.selectedModel,
      selectedCollection = savedConfiguration.selectedCollection,
      selectedLang = savedConfiguration.selectedLang,
      selectedDayPeriod = savedConfiguration.selectedDayPeriod;
  var models = data.models || [];

  if (!(models !== null && models !== void 0 && models.length)) {
    return {
      error: {
        message: "There are no models",
        code: 1000
      }
    };
  }

  var model = selectedModel ? models.filter(function (model) {
    return model.key === selectedModel;
  })[0] : models[0];

  if (selectedModel && !model) {
    return {
      error: {
        message: "Outdated configuration, there is not model with that key: " + selectedModel,
        code: 1100
      }
    };
  }

  var languages = ((_data$settings4 = data.settings) === null || _data$settings4 === void 0 ? void 0 : (_data$settings4$basic = _data$settings4.basic_settings) === null || _data$settings4$basic === void 0 ? void 0 : _data$settings4$basic.languages) || [];
  var configuration = {
    selectedLang: languages.indexOf(selectedLang) > -1 ? selectedLang : languages[0],
    selectedDayPeriod: ["day", "night"].indexOf(selectedDayPeriod) > -1 ? selectedDayPeriod : "day",
    selectedModel: model.key,
    price: (0, _objectSpread2["default"])({}, model.price),
    steps: {}
  };
  Object.keys(languages).forEach(function (k) {
    configuration.price[languages[k]] = getPrice(configuration.price, data, languages[k]);
  });
  var collections = model.collections || [];
  var collection = selectedCollection ? collections.filter(function (collection) {
    return collection.key === selectedCollection;
  })[0] : null;

  if (selectedCollection && !collection) {
    return {
      error: {
        message: "Outdated configuration, there is not collection with that key: " + selectedCollection,
        code: 1101
      }
    };
  }

  selectedCollection = collection === null || collection === void 0 ? void 0 : collection.key;
  var steps = model.steps;

  if (!(steps !== null && steps !== void 0 && steps.length)) {
    if (isAuthorOrAdmin) {
      console.warn({
        message: "There are no steps",
        code: 1001,
        configuration: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, configuration), {}, {
          selectedCollection: selectedCollection
        })
      });
    }

    return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, configuration), {}, {
      selectedCollection: selectedCollection
    });
  }

  var errors = {};
  var answerCollections = {};
  var custom = false;
  steps.forEach(function (step, si) {
    var _step$options;

    if (step !== null && step !== void 0 && (_step$options = step.options) !== null && _step$options !== void 0 && _step$options.length) {
      step.options.forEach(function (question, qi) {
        var _question$options;

        if (question !== null && question !== void 0 && (_question$options = question.options) !== null && _question$options !== void 0 && _question$options.length) {
          var tempPrice = JSON.parse(JSON.stringify(configuration.price));

          if (!configuration.steps[step.key]) {
            configuration.steps[step.key] = {};
          }

          var cheapestAnswer = (0, _toConsumableArray2["default"])(question.options.sort(function (a, b) {
            return (a === null || a === void 0 ? void 0 : a.price) < (b === null || b === void 0 ? void 0 : b.price);
          }))[0];
          var answer = (collection === null || collection === void 0 ? void 0 : collection.key) && question.options.filter(function (answer) {
            return answer.collections && answer.collections.indexOf(collection.key) > -1;
          })[0] || cheapestAnswer;

          if (!answer) {
            if (!errors[step.key]) {
              errors[step.key] = {
                message: "There are no answers " + step.key + "." + question.key,
                code: 1002
              };
            }

            if (!errors[step.key].errors) {
              errors[step.key].errors = {};
            }

            errors[step.key].errors[question.key] = {
              message: "There are no answers " + step.key + "." + question.key,
              code: 1002
            };
          }

          if (answer.key) {
            configuration.steps[step.key][question.key] = JSON.parse(JSON.stringify(answer));
            Object.keys(configuration.price).forEach(function (k) {
              configuration.price[k] = configuration.price[k] + getPrice(answer.price, data, k);
            });
            answerCollections[si.toString() + qi.toString() + "_" + step.key + "." + question.key] = configuration.steps[step.key][question.key].collections;
          }

          if (savedConfiguration.steps) {
            if (!savedConfiguration.steps[step.key]) {
              errors[step.key] = {
                message: "Outdated configuration, missing step from saved configuration " + step.key,
                code: 1102
              };
            }

            if (savedConfiguration.steps[step.key] && !savedConfiguration.steps[step.key][question.key]) {
              if (!errors[step.key]) {
                errors[step.key] = {
                  message: "Outdated configuration, missing answer " + step.key + "." + question.key,
                  code: 1102
                };
              }

              if (!errors[step.key].errors) {
                errors[step.key].errors = {};
              }

              errors[step.key].errors[question.key] = {
                message: "Outdated configuration, missing answer " + step.key + "." + question.key,
                code: 1102
              };
            }

            if (savedConfiguration.steps[step.key][question.key]) {
              var savedAnswer = savedConfiguration.steps[step.key][question.key];
              var foundAnswer = question.options.filter(function (answer) {
                return answer.key === savedAnswer.key;
              })[0];

              if (!foundAnswer) {
                if (!errors[step.key]) {
                  errors[step.key] = {
                    message: "Outdated configuration, this option no longer exists " + step.key + "." + question.key,
                    code: 1103
                  };
                }

                if (!errors[step.key].errors) {
                  errors[step.key].errors = {};
                }

                errors[step.key].errors[question.key] = {
                  message: "Outdated configuration, this option no longer exists " + step.key + "." + question.key,
                  code: 1103
                };
              }

              if (foundAnswer && foundAnswer.key !== answer.key) {
                configuration.steps[step.key][question.key] = JSON.parse(JSON.stringify(foundAnswer));
                Object.keys(tempPrice).forEach(function (k) {
                  if (foundAnswer.price[k] > 0 || typeof foundAnswer.price[k] == "undefined") {
                    tempPrice[k] = tempPrice[k] + getPrice(foundAnswer.price, data, k);
                  }
                });
                configuration.price = tempPrice;
                answerCollections[si.toString() + qi.toString() + "_" + step.key + "." + question.key] = configuration.steps[step.key][question.key].collections;
                custom = true;
              }
            }
          }
        }
      });
    }
  });
  Object.keys(configuration.price).forEach(function (k) {
    configuration.price[k] = getPrice(configuration.price, data, k);
    configuration.price[k] = typeof configuration.price[k] == "number" ? Math.round(configuration.price[k]) : 0;
  });

  if (Object.keys(errors).length) {
    return {
      error: errors
    };
  }

  var foundCollection = "";
  collections.forEach(function (collection) {
    if (!foundCollection) {
      var allAnswerFromThis = true;

      if (Object.keys(answerCollections).filter(function (key) {
        return !answerCollections[key] || answerCollections[key].indexOf(collection.key) === -1;
      }).length) {
        allAnswerFromThis = false;
      }

      if (allAnswerFromThis) {
        foundCollection = collection.key;
      }
    }
  });
  configuration.selectedCollection = foundCollection;
  configuration.isCustomCollection = !configuration.selectedCollection && custom;

  if (isAuthorOrAdmin) {
    if (!custom && !selectedCollection && !configuration.selectedCollection) {
      console.warn({
        message: "Configurator settings warning, the one of the collections (usually: 'basic') answers should be from the cheapest collection",
        code: 1004,
        answerCollections: answerCollections,
        configuration: configuration
      });
    }

    if (!custom && selectedCollection && !configuration.selectedCollection) {
      console.warn({
        message: "Configurator settings warning, the '" + selectedCollection + "' answers should be there of the all questions",
        code: 1005,
        answerCollections: answerCollections,
        configuration: configuration
      });
    }
  }

  return configuration;
}

function getInitialConfiguration(_ref2) {
  var data = _ref2.data,
      savedData = _ref2.savedData,
      isAuthorOrAdmin = _ref2.isAuthorOrAdmin;
  var initialConfiguration;

  try {
    initialConfiguration = getConfiguration({
      data: data,
      isAuthorOrAdmin: isAuthorOrAdmin
    });

    if (initialConfiguration.error) {
      if (isAuthorOrAdmin) {
        console.error({
          message: "The configurator cannot be loaded",
          code: initialConfiguration.error.code,
          originalError: initialConfiguration.error
        });
      }

      initialConfiguration = null;
    }
  } catch (e) {
    if (isAuthorOrAdmin) {
      console.error({
        message: "The configurator cannot be loaded",
        code: 1003,
        originalError: e
      });
    }
  }

  if (savedData !== null && savedData !== void 0 && savedData.configuration) {
    try {
      var savedConfiguration = getConfiguration({
        data: data,
        savedConfiguration: savedData.configuration,
        isAuthorOrAdmin: isAuthorOrAdmin
      });

      if (savedConfiguration.error) {
        console.warn({
          message: "The saved configuration cannot be loaded",
          code: savedConfiguration.error.code,
          originalError: savedConfiguration.error
        });
      }

      if (!savedConfiguration.error) {
        initialConfiguration = savedConfiguration;
      }

      if (JSON.stringify(savedConfiguration.price) !== JSON.stringify(savedData.configuration.price)) {
        console.log("Price changed alert will come to user");
      }
    } catch (e) {
      console.warn({
        message: "The saved configuration cannot be loaded",
        code: 1104,
        originalError: e
      });
    }
  }

  return initialConfiguration;
}

function getDataAndInitialConfiguration() {
  var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var context = p.context,
      appContext = p.appContext,
      postContext = p.postContext;
  var routes = appContext.routes,
      storage = appContext.storage;
  var wapp = context.wapp,
      req = context.req,
      res = context.res;
  var isAuthorOrAdmin = getIsAuthorOrAdmin({
    appContext: appContext,
    postContext: postContext
  });
  var data = {};

  try {
    var _postContext$post;

    data = postContext.post && typeof postContext.post.content == "string" ? JSON.parse(postContext.post.content) : (_postContext$post = postContext.post) !== null && _postContext$post !== void 0 && _postContext$post.content ? postContext.post.content : {};
  } catch (e) {//console.log(e)
  }

  var savedData = storage();
  var query = req.wappRequest.query;
  var initialState = typeof window !== "undefined" && window[wapp.config.appStateName] || {
    req: {
      timestamp: Date.now()
    }
  };
  var utils = (0, _getUtils["default"])(context);
  var firstRender = utils.getGlobalState().req.timestamp === initialState.req.timestamp || wapp.target === "node";

  if (query.configuration && firstRender) {
    try {
      var configuration = JSON.parse(query.configuration);
      savedData = storage((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, Object.fromEntries(Object.keys(savedData).map(function (key) {
        return key === "drawerOpen" ? [key, savedData[key]] : [key, undefined];
      }))), {}, {
        configuration: configuration
      }));
    } catch (e) {
      console.log(e);
    }
  }

  var modelFromRoute = res.wappResponse.route.requestPath.startsWith(routes.modelsRoute) && res.wappResponse.route.params.model;

  if (modelFromRoute && data.models.find(function (model) {
    return model.key === modelFromRoute;
  })) {
    if (savedData.configuration && savedData.configuration.selectedModel !== modelFromRoute) {//alert
    }

    savedData = storage((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, savedData), {}, {
      configuration: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, savedData.configuration ? savedData.configuration : {}), {}, {
        selectedModel: modelFromRoute
      })
    }));
  }

  var initialConfiguration = p.initialConfiguration || getInitialConfiguration({
    data: data,
    savedData: savedData,
    isAuthorOrAdmin: isAuthorOrAdmin
  });
  return {
    data: data,
    initialConfiguration: initialConfiguration
  };
}

function getIsAuthorOrAdmin(_ref3) {
  var _post$_author;

  var appContext = _ref3.appContext,
      postContext = _ref3.postContext;
  var userStatusManager = appContext.userStatusManager;
  var user = postContext.user,
      post = postContext.post;
  var isAdmin = user && user[userStatusManager === null || userStatusManager === void 0 ? void 0 : userStatusManager._status_isFeatured];
  var isAuthor = (user === null || user === void 0 ? void 0 : user._id) && (user === null || user === void 0 ? void 0 : user._id) === (post === null || post === void 0 ? void 0 : post._author) || (user === null || user === void 0 ? void 0 : user._id) && (user === null || user === void 0 ? void 0 : user._id) === (post === null || post === void 0 ? void 0 : (_post$_author = post._author) === null || _post$_author === void 0 ? void 0 : _post$_author._id);
  return isAdmin || isAuthor;
}

function getQueryString(configuration) {
  return configuration ? "?configuration=" + encodeURIComponent(JSON.stringify(Object.fromEntries(Object.keys(configuration).map(function (key) {
    if (key === "steps") {
      var steps = configuration[key];
      return [key, Object.fromEntries(Object.keys(steps).map(function (key) {
        var step = steps[key];
        return [key, Object.fromEntries(Object.keys(step).map(function (key) {
          return [key, {
            key: step[key].key
          }];
        }))];
      }))];
    } else {
      return [key, configuration[key]];
    }
  })))) : "";
}