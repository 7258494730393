import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";

export default function getConstants(p = {}) {

    const name = "user";

    const n = name;
    const N = capitalize(n);
    const ns = (name.endsWith("y")) ? name.slice(0,-1)+"ies" : name+"s";
    const Ns = capitalize(ns);

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
            ...p,
            name
        });

    return {
        labels: {
            ...labels,

            ["new"+N+"SubmitLabel"]: "Save",
            ["restore"+N+"SubmitLabel"]: "Restore",
            ["save"+N+"SubmitLabel"]: "Save",
            ["cancel"+N+"Text"]: "Cancel",
            ["delete"+N+"Text"]: "Delete",
            ["delete"+Ns+"Text"]: "Delete users",
            ["ban"+N+"Text"]: "Ban",
            ["approve"+N+"Text"]: "Approve",
            ["protection"+N+"Text"]: "Admin",
            ["removeProtection"+N+"Text"]: "Remove admin",

            [n+"ContainerLabel_record_name"]: "Name",

            loginSubmitLabel: "Login",
            logoutSubmitLabel: "Logout",
            signupSubmitLabel: "Sign up",
            forgotPasswordSubmitLabel: "Send reset key",
            resetPasswordSubmitLabel: "Reset password",
            changeEmailSubmitLabel: "Change email",
            changePasswordSubmitLabel: "Change password",
            emailConfirmationSubmitLabel: "Confirm email",
            deleteAccountLabel: "Delete account",
            emailConfirmationSendAgainSubmitLabel: "Send confirmation key to new email address again",
            acceptTermsLabel: "Accept terms",
            acceptPrivacyLabel: "Accept privacy",
        },
        menus: {
            ...menus,
            ["user"+Ns+"Menu"]: Ns,
            ["my"+Ns+"Menu"]: "My "+ns,
            ["new"+N+"Menu"]: "New "+n,
            [ns+"Menu"]: Ns,
            ["deleted"+Ns+"Menu"]: "Deleted "+ns,
            ["banned"+Ns+"Menu"]: "Banned "+ns,
            ["protection"+Ns+"Menu"]: "Admins",
            ["missingData"+Ns+"Menu"]: "Missing data",
            ["lowAuthorStatus"+Ns+"Menu"]: "",

            ["edit"+N+"Menu"]: "Edit",
            ["delete"+N+"Menu"]: "Delete",
            ["ban"+N+"Menu"]: "Ban",
            ["approve"+N+"Menu"]: "Approve",
            ["protection"+N+"Menu"]: "Add admin",
            ["removeProtection"+N+"Menu"]: "Remove admin",

            loginMenu: "Login",
            signupMenu: "Signup",
            changeData: "Change data",
            changeEmail: "Change email",
            emailConfirmation: "Email confirmation",
            changePassword: "Change password",
            forgotPasswordMenu: "Forgot password",
            deleteAccount: "Delete account",
            logoutMenu: "Logout",

            accountSettingsMenu: "Account settings",
            dashboardMenu: "Dashboard",
            userProfileMenu: "User profile",
        },
        messages: {
            ...messages,

            ["new"+N+"SuccessMessage"]: "The new user has beed saved",
            ["save"+N+"SuccessMessage"]: "The user has beed saved",
            ["delete"+N+"Success"]: "This user has been deleted",
            ["delete"+Ns+"Success"]: "Users have been deleted",
            ["delete"+N+"Question"]: "Are you sure want to delete this user?",
            ["delete"+Ns+"Question"]: "Are you sure want to delete these users?",
            ["ban"+N+"Success"]: "This user has been banned",
            ["ban"+N+"Question"]: "Are you sure want to ban this user?",
            ["approve"+N+"Success"]: "This user has been approved",
            ["approve"+N+"Question"]: "Are you sure want to approve this user?",
            ["protection"+N+"Success"]: "This user has been added to as an admin",
            ["protection"+N+"Question"]: "Are you sure want add admin access to this user?",
            ["removeProtection"+N+"Success"]: "The admin access of this user has been removed",
            ["removeProtection"+N+"Question"]: "Are you sure want to remove admin access of this user?",

            forgotPasswordSuccessMessage: "The message has been sent to your email address",
            resetPasswordSuccessMessage: "Your password has been updated",
            changePasswordSuccessMessage: "Your password has been updated",
            changeEmailSuccessMessage: "Your email has been updated",
            changeDataSuccessMessage: "Your data has been updated",
            emailConfirmationSuccessMessage: "Your email has been confirmed",
            emailConfirmationSendAgainSuccessMessage: "Your email confirmation key has been sent again",
            deleteAccountSuccessMessage: "Your account has been deleted",
            deleteAccountQuestion: "Are you sure want to delete your account?",
            deleteAccountSuccess: "Your account has been deleted",
        },
        routes: {
            ...routes,
            ["user"+Ns+"Route"]: "/"+ns,
            [n+"Route"]: "/"+n,
            [ns+"Route"]: "/"+ns,
        },
        titles: {
            ...titles,
            [n+"Title"]: N,
            [ns+"Title"]: Ns,
            ["new"+N+"Title"]: "New "+n,
            ["edit"+N+"Title"]: "Edit "+n,
            ["user"+Ns+"Title"]: Ns,
            ["userDeleted"+Ns+"Title"]: "Deleted "+ns,
            ["my"+Ns+"Title"]: "My "+ns,
            ["myDeleted"+Ns+"Title"]: "My deleted "+ns,

            ["dialogDelete"+N+"Title"]: "Delete user",
            ["dialogBan"+N+"Title"]: "Ban user",
            ["dialogApprove"+N+"Title"]: "Approve user",
            ["dialogProtection"+N+"Title"]: "Add admin",
            ["dialogRemoveProtection"+N+"Title"]: "Remove admin",

            ["dialogDelete"+Ns+"Title"]: "Delete users",

            ["statusBanned"+N+"Title"]: "Banned",
            ["statusDeleted"+N+"Title"]: "Deleted",
            ["statusAuthorDeleted"+N+"Title"]: "Author deleted",
            ["statusMissingData"+N+"Title"]: "Missing data",
            ["statusApproved"+N+"Title"]: "Approved",
            ["statusFeatured"+N+"Title"]: "Admin",
            ["statusCreated"+N+"Title"]: "Registrated",

            ["banned"+Ns+"Title"]: "Banned "+ns,
            ["deleted"+Ns+"Title"]: "Deleted "+ns,
            ["missingData"+Ns+"Title"]: "Missing data",
            ["protected"+Ns+"Title"]: "Admins",
            ["lowAuthorStatus"+Ns+"Title"]: "",

            ["get"+N+"Description"]: (name)=>name +"'s page",

            loginTitle: "Login",
            accountTitle: "Account settings",
            changeDataTitle: "Change data",
            changeEmailTitle: "Change email",
            emailConfirmationTitle: "Email confirmation",
            signupTitle: "Signup",
            logoutTitle: "Logout",
            forgotPasswordTitle: "Forgot password",
            resetPasswordTitle: "Reset password",
            changePasswordTitle: "Change password",
            deleteAccountTitle: "Delete account",

            dialogDeleteAccountTitle: "Delete account",
            userProfileTitle: "User profile",
            dashboardTitle: "Dashboard",
            acceptTermsTitle: "Accept terms",
            acceptPrivacyTitle: "Accept privacy"
        }
    };
}
