// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0z45H{}\r\n\r\n.OPNKs{}\r\n\r\n.OPNKs pre {\r\n    background-color: #303338!important;\r\n}\r\n\r\n@media (prefers-color-scheme: dark) {\r\n    .OPNKs pre {\r\n        background: rgba(0, 0, 0, 0.14) !important;\r\n    }\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": "_0z45H",
	"content": "OPNKs"
};
module.exports = ___CSS_LOADER_EXPORT___;
