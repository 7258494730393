import React from "react";

import {createChildWapp} from "configurator-studio-arkitekter-app";

import App from "../../components/App";
import capitalize from "../../utils/capitalize";
import {runPostTypesConfig} from "../index";

import Embed from "./Embed";
import {getTitleFunction, getDescriptionFunction, getListContent} from "../post/contents";

import collectRouteParams from "../../utils/collectRouteParams";

export default function setContents(p = {}) {

    const {wapp, messages, routes, titles, menus, labels, name = "configurator", getTitle = function ({title}) {return title;}} = p;
    const n = name;
    const N = capitalize(n);
    const ns = n+"s";

    const urlParams = [
        "/page/:pagination",
        "/sort/:sort",
        "/limit/:limit"
    ];

    let reqUserForPost = null;

    async function request ({wapp, req, res}) {
        const wappResponse = res.wappResponse;
        const post = wappResponse.store.getState("res.responses." + n + "FindById");
        const route = wappResponse.route;
        const {params} = route;
        const {_id, page} = params;

        const statusManager = wapp.getTargetObject().postTypes.findPostType({name: n}).statusManager;

        await runPostTypesConfig({action: "requestFor" + N + "Page", p: {wapp, req, res, statusManager}});

        if ((_id && page !== "new") || (_id && page !== "edit")) {

            const url = "/"+"/:page/:innerPage1/:innerPage2/:innerPage3".split("/:").filter((key)=>params[key]).map((key)=>params[key]).join("/");
            const queryString = Object.keys(req.wappRequest.query).map((key) => ""+key+"="+encodeURIComponent(req.wappRequest.query[key])).join("&");

            const configuratorChild = await createChildWapp({
                parentWapp: wapp,
                parentReq: req,
                parentRoute: (route.path.match("embed")) ? routes[n+"Route"] + "/embed/" +_id : routes[n+"Route"] + "/" +_id,
                config: {globals: {WAPP: wapp.globals.WAPP + "_BKA_" + _id}},
                req: {
                    path: url,
                    url: (queryString) ? url + "?" +queryString : url
                }
            });

            res.wappResponse["configurator-studio-arkitekter-app"] = {...configuratorChild};

        }

        if ((_id && !post) ||
            (_id && post && post._id !== _id) ||
            (_id && reqUserForPost?._id !== req.wappRequest.user?._id)) {
            reqUserForPost = {_id: req.wappRequest.user?._id};
            return await wapp.requests.send({requestName: n + "FindById", args: {_id: _id}, req, res});
        }

    }

    const titleFunction = getTitleFunction({name, titles, routes, getTitle});

    wapp.contents.add({
        [n]: {
            title: titleFunction,
            description: getDescriptionFunction({name, getTitle: titleFunction}),
            render: App,
            renderType: "react",
            request: request
        },
    });

    wapp.contents.add({
        [ns]: getListContent({name, titles, routes, getTitle, urlParams})
    });

    wapp.contents.add({
        [n+"_embed"]: {
            title: titleFunction,
            description: getDescriptionFunction({name, getTitle: titleFunction}),
            render: (props)=> <Embed {...props} name={n} appContext={{messages, routes, menus, titles, labels}} />,
            renderType: "react",
            request: request
        }
    });

    wapp.contents.add({
        [n+"_api"]: {
            render: function (req, res) {

                res.setHeader("Access-Control-Allow-Origin", "*");
                res.setHeader("Access-Control-Allow-Headers", "*");
                res.setHeader("Access-Control-Allow-Methods", "POST, OPTIONS");
                if (req.method === "OPTIONS"){
                    return {};
                }

                const wappResponse = res.wappResponse;
                const state = wappResponse.store.getState();
                const post = state.res.responses && state.res.responses[n+"FindById"] || {};
                return {...post}
            },
            renderType: "post",
            request: request
        },
    });

    const paths = collectRouteParams({
        startRoutes: [
            {path: routes[ns+"Route"], contentName: ns},
            {path: routes[ns+"Route"]+"/:page", contentName: ns},
        ],
        params: urlParams
    });

    wapp.router.add([
        {path: routes[n+"Route"], contentName: n},
        {path: routes[n+"Route"]+"/new", contentName: n},
        {path: routes[n+"Route"]+"/embed/:_id", contentName: n+"_embed"},
        {path: routes[n+"Route"]+"/embed/:_id/:page", contentName: n+"_embed"},
        {path: routes[n+"Route"]+"/embed/:_id/:page/:innerPage1/", contentName: n+"_embed"},
        {path: routes[n+"Route"]+"/embed/:_id/:page/:innerPage1/:innerPage2/:innerPage3", contentName: n+"_embed"},
        {path: routes[n+"Route"]+"/:_id", contentName: n},
        {path: routes[n+"Route"]+"/:_id/:page", contentName: n},
        {path: routes[n+"Route"]+"/:_id/:page/:innerPage1", contentName: n},
        {path: routes[n+"Route"]+"/:_id/:page/:innerPage1/:innerPage2/:innerPage3", contentName: n},
        {path: (wapp.globals.DEV) ? "/configurator-api/:_id" : "/c" + wapp.globals.WAPP+"/:_id", contentName: n+"_api"},

        ...paths

    ]);

    return {
        name: n
    };

}
