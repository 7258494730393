// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R2uyx{}\n\n.R2uyx > div {\n    margin-bottom: 16px;\n}\n\n.R2uyx > div:last-child {\n    margin-bottom: 0;\n}\n\n._9NsJ9{\n    font-size: 14px;\n    line-height: 20px;\n    color: var(--font-color);\n    letter-spacing: normal;\n}\n\n._9NsJ9 pre {\n    background-color: #303338!important;\n}\n\n@media (prefers-color-scheme: dark) {\n    ._9NsJ9 pre {\n        background: rgba(0, 0, 0, 0.14) !important;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": "R2uyx",
	"content": "_9NsJ9"
};
module.exports = ___CSS_LOADER_EXPORT___;
