// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iF1wG {}\n\n.iF1wG.vKvmn {\n    font-size: 14.4px;\n    font-size: 0.9rem;\n}\n\n.iF1wG.mxQPR{\n    font-size: 17.6px;\n    font-size: 1.1rem;\n}\n\n.iF1wG.AeAC8 {\n    font-size: 28px;\n    font-size: 1.75rem;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "iF1wG",
	"small": "vKvmn",
	"list": "mxQPR",
	"big": "AeAC8"
};
module.exports = ___CSS_LOADER_EXPORT___;
