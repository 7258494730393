import ImageIcon from "@material-ui/icons/Image";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageOutlined from "@material-ui/icons/ImageOutlined";

import defaultPostTypeConfig from "../post";
import capitalize from "../../utils/capitalize";
import Content from "./Content";
import New from "./New";
import getConstants from "./constants";

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    fileUrl: { type: String },
                },
                ...(p.config) ? p.config : {}
            },
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {
        return defaultPostTypeConfig.setContents({
            ...p,
            name:"file"
        })
    },
    requestForUserPage: async function requestForUserPage(p = {}) {
        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name:"file"
        })
    },
    getPages: function () {
        return {
            content: Content,
            new: New
        }
    },
    getComponent: function (p) {

        const name = "file";
        const ns = (name.endsWith("y")) ? name.slice(0,-1)+"ies" : name+"s";

        const {context, appContext} = p;
        const {routes} = appContext;

        const {res, req} = context;
        const route = res.wappResponse.route;
        const requestPath = route.requestPath;
        const user = req.wappRequest.user;

        const {params} = route;
        const {page} = params;

        const isAdmin = user && user._status_isFeatured;

        const pages = {
            content: Content,
            new: New
        };

        if ((requestPath.startsWith(routes[name+"Route"])) || (requestPath.startsWith(routes[ns+"Route"]))){

            if (requestPath.startsWith(routes[ns+"Route"]) && !isAdmin) {
                return null;
            }

            const requestKeys = [name+"FindById"];
            const post = requestKeys.map((requestName)=>res.wappResponse.store.getState("res.responses."+requestName)).find((r)=>r?._id);
            const isAuthor = ((user?._id && user._id.toString() === post?._author) || (user?._id && user._id.toString() === post?._author?._id));

            const isAuthorOrAdmin = (isAuthor || isAdmin);

            if (requestPath.startsWith(routes[name+"Route"]) && requestPath !== routes[name+"Route"]+"/new" && !isAuthorOrAdmin) {
                return null;
            }

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                componentProps:{
                    pages,
                    contentClassName:(!page && requestPath !== routes[name+"Route"] + "/new") ? "noPadding" : ""
                }
            });
        }
    },
    userPageFunctions: function (p) {

        const {context, /*appContext, */postContext} = p;

        const n = "file";
        const ns = (n.endsWith("y")) ? n.slice(0,-1)+"ies" : n+"s";
        const N = capitalize(n);
        const Ns = capitalize(ns);

        const r = defaultPostTypeConfig.userPageFunctions({...p, name:n});

        const {res} = context;

        const wappResponse = res.wappResponse;
        const route = wappResponse.route;
        const {params} = route;
        const {pageType} = params;

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const {appContext} = props;
            const {menus, routes, /*userStatusManager*/} = appContext;

            return [
                {
                    name: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        return (isAuthor) ? menus["my"+Ns+"Menu"] : menus[postContext.name+Ns+"Menu"];
                    },
                    href: function (p) {
                        return (p.post?._id) ? "/"+p.post._id + routes[postContext.name+Ns+"Route"] : routes[postContext.name+Ns+"Route"];
                    },
                    role: function (p) {
                        return  p.user && p.user._status_isFeatured;
                    },
                    Icon: ImageIcon,
                    order: 41,
                },
                {
                    name: function () {
                        return menus["deleted"+Ns+"Menu"];
                    },
                    href: function (p) {
                        return (p.post?._id) ? "/"+p.post._id + routes[postContext.name+Ns+"Route"]+"/deleted" : routes[postContext.name+Ns+"Route"]+"/deleted"
                    },
                    role: function (p) {
                        const isAdmin = p.user && p.user._status_isFeatured;
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAuthorOrAdmin = (isAdmin || isAuthor);
                        const isPostsPage = ((p.page === ns && !p.pageType) || (p.page === ns && !isNaN(Number(p.pageType)) && Number(pageType) >= 1 ));
                        return (isPostsPage && isAuthorOrAdmin);
                    },
                    Icon: DeleteIcon,
                    onlyIcon: true,
                    featured: true,
                },
            ];
        }

        function addContentMenuItems(props) {

            const {appContext} = props;
            const {menus, routes} = appContext;

            return [
                {
                    name: menus["new"+N+"Menu"],
                    href: routes[n+"Route"] + "/new",
                    role: function (p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                        const isAdmin = p.user && p.user._status_isFeatured;
                        return isAdmin && isAuthor;
                    },
                    Icon: ImageIcon,
                    disableParentRoute: true,
                    order: 41,
                },
                {
                    name: function (p) {
                        const isAuthor = ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id));
                        return (isAuthor) ? menus["my"+Ns+"Menu"] : menus[postContext.name+Ns+"Menu"];
                    },
                    href: function (p) {
                        return (p?.post?._id) ? "/"+p.post._id + routes[postContext.name+Ns+"Route"] : routes[postContext.name+Ns+"Route"];
                    },
                    role: function () {
                        return false;
                    },
                    Icon: ImageIcon,
                    order: 42,
                },
            ]
        }

        function getPageName({user, post, page}) {

            const isAdmin = user && user._status_isFeatured;
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id));
            const isAuthorOrAdmin = (isAdmin || isAuthor);

            return ((page === ns && !pageType) || (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1 ) || (page === ns && pageType === "deleted" && isAuthorOrAdmin)) ? (isAuthorOrAdmin) ? page : "notFound" : null;

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName
        }
    },
    adminMenu: function (p) {
        const name = "file";
        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({...p, name});
        defaultAdminMenu.Icon = ImageIcon;
        defaultAdminMenu.order = 5;
        defaultAdminMenu.items[0].Icon = ImageOutlined;
        return defaultAdminMenu;
    }
};

export default postTypeConfig;
