import getStatusManager from "./status";
import getConstants from "./constants";
import setContents, {requestForUserPage, adminMenu} from "./contents";
import getComponent from "./component";

const postTypeConfig = {
    getStatusManager,
    getConstants,
    setContents,
    requestForUserPage,
    getComponent,
    adminMenu
};

export default postTypeConfig;
