export default function makeMaterialStyle(theme) {
    return {
        root: {
            "& $avatar": {},
            "& $small": {
                width: theme.spacing(3),
                height: theme.spacing(3),
            },
            "& $list": {
                width: theme.spacing(4),
                height: theme.spacing(4),
            },
            "& $big": {
                width: theme.spacing(7),
                height: theme.spacing(7),
            },
        },
        avatar: {},
        small: {},
        list: {},
        big: {},
    }
}
