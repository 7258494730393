import React from "react";

import style from "./style.css";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MaterialSelect from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";

export default function Select(props) {

    const {onChange, label = "", disabled, error, helperText, multiple, required, options = [], defaultValue, ...rest} = props;

    let value = (
        (multiple && typeof props.value === "object" && props.value.length) ||
        (!multiple)
    ) ? props.value : (multiple) ? [] : "";

    if ((!multiple && required && !value) ||
        (multiple && required && !value.length)){
        value = typeof defaultValue !== "undefined" ? defaultValue : options[0].value;
    }

    return (
        <div className={style.select}>
            <FormControl error={error} fullWidth>
                <InputLabel>{label}</InputLabel>
                <MaterialSelect
                    {...rest}
                    label={label}
                    value={value}
                    disabled={disabled}
                    {...(multiple) ? {multiple: true} : {}}
                    onChange={(e)=> {
                        const value = e.target.value;
                        onChange({target: {value}});
                    }}
                >
                    {options.map((o, i)=>{
                        return <MenuItem key={i} disabled={o.disabled} value={o.value}>{o.label}</MenuItem>
                    })}
                </MaterialSelect>
                {(helperText) ? <FormHelperText>{helperText}</FormHelperText> : null}
            </FormControl>
        </div>
    )
}
