export default function collectRouteParams(p = {}) {

    const {startRoutes = [{path:"/", contentName:"post"}], params = ["/page/:pagination", "/sort/:sort", "/limit/:limit", "/tags/:tags"]} = p;

    const getPermutations = function(list, maxLen) {
        const perm = list.map(function(val) {
            return [val];
        });
        const generate = function(perm, maxLen, currLen) {
            if (currLen === maxLen) {
                return perm;
            }
            for (let i = 0, len = perm.length; i < len; i++) {
                const currPerm = perm.shift();
                for (let k = 0; k < list.length; k++) {
                    if (currPerm.indexOf(list[k]) === -1) {
                        perm.push(currPerm.concat(list[k]));
                    }
                }
            }
            return generate(perm, maxLen, currLen + 1);
        };
        return generate(perm, maxLen, 1);
    };

    const r = [];

    params.forEach((v, i)=>{
        r.push(...getPermutations(params, i+1).map((parts)=>parts.join("")))
    });

    return startRoutes.reduce((a, {path, contentName})=>{
        a.push(
            {path, contentName},
            ...r.map((parts)=>({path: path+parts.replace("//", "/"), contentName}))
        );
        return a;
    }, [])

}
