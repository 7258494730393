// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0tefm{\n    position: relative;\n    width: 100%;\n    height: 100%;\n    min-height: inherit;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: center;\n        justify-content: center;\n    -ms-flex-align: center;\n        align-items: center;\n}\n\n.gLHRJ{\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: center;\n        justify-content: center;\n    -ms-flex-align: center;\n        align-items: center;\n    width: 100%;\n    height: 100%;\n    padding-bottom: 128px;\n    -ms-flex-direction: column;\n        flex-direction: column;\n}\n\n.SaiwD{\n    width: 100%;\n    padding: 16px;\n}\n\n.nOMp0{\n    width: 100%;\n    -ms-flex-pack: center;\n        justify-content: center;\n    display: -ms-flexbox;\n    display: flex;\n    padding: 16px;\n}\n\n.GGlxy{\n    height: 128px;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: center;\n        justify-content: center;\n    -ms-flex-align: center;\n        align-items: center;\n    margin-bottom: 32px;\n}\n\n.Ttmak{\n    height: 24px;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: center;\n        justify-content: center;\n    -ms-flex-align: center;\n        align-items: center;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": "_0tefm",
	"centerContainer": "gLHRJ",
	"logoContainer": "SaiwD",
	"buttons": "nOMp0",
	"logo": "GGlxy",
	"SA": "Ttmak"
};
module.exports = ___CSS_LOADER_EXPORT___;
