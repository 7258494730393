import React from "react";

const SchemaFormContext = React.createContext({
    rootData: {},
    style:{},
    storage: function () {},
    validationFunction: function () {return true},
    stateManager:{},
    dialog: {actions:{}},
    update: async function () {},
});

export default SchemaFormContext;
