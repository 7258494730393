export const defaultDescriptor = {
    writable: true,
    enumerable: true,
    configurable: false,
}

export default function createStateManager() {

    const defaultReducers = Object.create(Object.prototype, {
        invalid: {
            ...defaultDescriptor,
            value: function(state = {}, action) {
                switch (action.type) {
                    case "SET_INVALID":
                        return {
                            ...state,
                            [action.payload.name]: action.payload.value,
                        };
                    default:
                        return state;
                }
            }
        },
        errorMessage: {
            ...defaultDescriptor,
            value: function(state = {}, action) {
                switch (action.type) {
                    case "SET_ERRORMESSAGE":
                        return {
                            ...state,
                            [action.payload.name]: action.payload.value,
                        };
                    default:
                        return state;
                }
            }
        },
        label: {
            ...defaultDescriptor,
            value: function(state = {}, action) {
                switch (action.type) {
                    case "SET_LABEL":
                        return {
                            ...state,
                            [action.payload.name]: action.payload.value,
                        };
                    default:
                        return state;
                }
            }
        },
    })

    const defaultActions = Object.create(Object.prototype, {
        invalid: {
            ...defaultDescriptor,
            value: function ({name, value}) {
                return {
                    type: "SET_INVALID",
                    payload: {
                        name,
                        value,
                    },
                }
            }
        },
        errorMessage: {
            ...defaultDescriptor,
            value: function ({name, value}) {
                return {
                    type: "SET_ERRORMESSAGE",
                    payload: {
                        name,
                        value,
                    },
                }
            }
        },
        label: {
            ...defaultDescriptor,
            value: function ({name, value}) {
                return {
                    type: "SET_LABEL",
                    payload: {
                        name,
                        value,
                    },
                }
            }
        },
    })

    const defaultRootReducer = function (newState, action) {
        Object.keys(stateManager.reducers).forEach(function(key){
            newState[key] = stateManager.reducers[key](newState[key], action)
        })
        return newState;
    }

    function createStore(initialState = {}) {

        const rootReducer = stateManager.rootReducer;

        let state = {};

        Object.keys(initialState).forEach(function (key) {
            state[key] = initialState[key]
        })

        return {
            getState: function() {
                try{
                    return JSON.parse(JSON.stringify(state));
                } catch (e){
                    console.log(e);
                    state = {};
                    Object.keys(initialState).forEach(function (key) {
                        state[key] = initialState[key]
                    })
                    return JSON.parse(JSON.stringify(state));
                }
            },
            dispatch: function(action) {
                let newState = this.getState();
                try {
                    newState = rootReducer(newState, action);
                } catch (e) {
                    console.log(e)
                }
                try {
                    Object.keys(newState).forEach(function (key){
                        state[key] = newState[key]
                    })
                } catch (e) {
                    console.log(e)
                }
                stateManager.runListeners(newState, action);
                return state;
            },
            subscribe: function (listener) {
                return stateManager.addListener(listener)
            },
            runAction(actionName, props) {
                return stateManager.actions[actionName](props)
            }
        }
    }

    function defaultAddListener(listener) {
        stateManager.listeners.push(listener);
        return function unsubscribe() {
            stateManager.removeListener(listener);
        }
    }

    function defaultRemoveListener(listener) {
        if (stateManager.listeners.indexOf(listener) !== -1){
            stateManager.listeners.splice(stateManager.listeners.indexOf(listener), 1)
        }
    }

    function defaultRunListeners(newState, action) {
        const listeners = [...stateManager.listeners];
        listeners.forEach(function (listener) {
            listener(newState, action)
        })
    }

    const stateManager = Object.create(Object.prototype, {
        reducers: {
            ...defaultDescriptor,
            writable: false,
            value: defaultReducers
        },
        rootReducer: {
            ...defaultDescriptor,
            writable: false,
            value: defaultRootReducer
        },
        actions: {
            ...defaultDescriptor,
            writable: false,
            value: defaultActions
        },
        createStore: {
            ...defaultDescriptor,
            value: createStore
        },
        listeners: {
            ...defaultDescriptor,
            writable: false,
            value: []
        },
        addListener: {
            ...defaultDescriptor,
            value: defaultAddListener
        },
        removeListener: {
            ...defaultDescriptor,
            value: defaultRemoveListener
        },
        runListeners: {
            ...defaultDescriptor,
            value: defaultRunListeners
        }
    })

    return stateManager;

}
