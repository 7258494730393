import React, {useContext} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import AppContext from "../../App/context";
import {withMaterialStyles} from "../../Template/withMaterial";
import Dialog from "../../Dialog";

import materialStyle from "./materialStyle";
import style from "./style.css";
import AccountContext from "../context";
import Button from "@material-ui/core/Button";
import capitalize from "../../../utils/capitalize";

function DeleteAccount(/*props*/) {

    const accountContext = useContext(AccountContext);
    const {user, parentRoute, name} = accountContext;

    const N = capitalize(name);

    const appContext = useContext(AppContext);
    const context = useContext(WappContext);
    const utils = getUtils(context);
    //const {materialStyle} = props;

    const {wapp} = context;

    async function onSubmit() {
        return await utils.sendRequest({requestName: name+"Delete", args: {_id: user?._id}, redirect: {pathname: parentRoute, search:"", hash:""}, timeOut:1000 });
    }

    let formDataFromResolvers = {};
    try {
        formDataFromResolvers = utils.getGlobalState("res.graphql.mutation."+name+"Delete.formData");
    } catch (e){}

    const formData = {
        ...formDataFromResolvers,
    };

    if (user?._id){
        formData._id.value = user;
        formData._id.disabled = true;
        formData._id.refPostType = name;
    }

    wapp.styles.use(style);

    const dialog = {
        actions: {}
    };

    const dialogEffect = function ({actions}) {
        dialog.actions = actions;
    };

    const onDelete = function () {
        dialog.actions.open({
            dialogTitle: appContext.titles.dialogDeleteAccountTitle,
            dialogContent: appContext.messages.deleteAccountQuestion,
            cancelText: appContext.labels["cancel"+N+"Text"],
            submitText: appContext.labels["delete"+N+"Text"],
            successMessage: appContext.messages.deleteAccountSuccess,
            onSubmit: onSubmit,
        })
    };

    return (
        <div>
            <Button
                className={style.deleteAccountButton}
                variant={"contained"}
                color={"secondary"}
                onClick={onDelete}
            >
                {appContext.labels.deleteAccountLabel}
            </Button>
            <Dialog effect={dialogEffect} />
        </div>
    )
}

const WappComponent = withWapp(DeleteAccount);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
