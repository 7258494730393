import defaultPostTypeConfig from "../post";

export default function getConstants(p = {}) {

    const name = "tag";

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    });

    return {
        labels: {
            ...labels
        },
        menus: {
            ...menus,
        },
        messages: {
            ...messages
        },
        routes: {
            ...routes,
        },
        titles: {
            ...titles,
        }
    }
}
