import React, {useContext, useState, useEffect} from "react";
import {withWapp, WappContext} from "wapplr-react/dist/common/Wapp";
import {copyObject} from "wapplr/dist/common/utils";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneIcon from "@material-ui/icons/Done";

import {withMaterialStyles} from "../Template/withMaterial";

import Posts from "./Posts";
import Checkbox from "./Checkbox";
import NumberField from "./NumberField";
import Select from "./Select";

import style from "./style.css";
import materialStyle from "./materialStyle";
import PostContext from "../Post/context";
import AccountContext from "../Account/context";
import AppContext from "../App/context";

export const defaultComponents = {
    Button: {
        props: {
            type: "submit",
            variant: "contained",
            color: "secondary",
            children: "Submit",
            disabled: false,
            startIcon: null
        },
        Component: (props)=>{

            const {submitRef, effect, disabled, startIcon, ...rest} = props;
            const [progress, setProgress] = useState(false);
            const [disabledState, setDisabled] = useState(disabled || false);
            const [done, setDone] = useState(false);

            useEffect(()=>{
                if (effect){
                    effect({
                        actions: {
                            setProgress,
                            setDisabled,
                            setDone
                        }
                    })
                }
                return ()=>{
                    if (effect){
                        effect({
                            actions: {
                                setProgress: async ()=>null,
                                setDisabled: async ()=>null,
                                setDone: async ()=>null,
                            }
                        })
                    }
                }
            }, [effect, progress, disabled, done]);

            return <Button {...rest} disabled={disabledState || progress} startIcon={(done) ? <DoneIcon /> : (progress) ? <CircularProgress size={24}/> : startIcon}/>
        }
    },
    Posts: {
        props: {
            label: "",
            thereAreNoEntriesMessage: "There are not entries",
            value: "",
            refPostType: "post",
            multiple: false,
            disabled: false,
            enableNew: false,
            NewComponent: null,
            accept: "",
            disableFindByAuthor: false,
            type: "",
            initialMaxPerPage: false,
            disablePageInfo: false,
            disableTable: false,
            error: false,
            helperText: "",
            author: null
        },
        Component: Posts
    },
    TextField: {
        props: {
            type: "text",
            label: "",
            value: "",
            error: false,
            helperText: "",
            variant: "outlined",
            autoComplete: "on",
            disabled: false,
            multiline: false,
            minRows: null,
            maxRows: null
        },
        Component: TextField
    },
    Checkbox: {
        props: {
            label: "",
            value: "",
            error: false,
            helperText: "",
        },
        Component: Checkbox
    },
    Select: {
        props: {
            label: "",
            value: "",
            error: false,
            helperText: "",
            options: [],
            multiple: false,
            required: false,
            defaultValue: ""
        },
        Component: Select
    },
    NumberField: {
        props: {
            type: "text",
            label: "",
            value: "",
            error: false,
            helperText: "",
            variant: "outlined",
            autoComplete: "on",
            disabled: false,
            multiline: false,
            minRows: null,
            maxRows: null
        },
        Component: NumberField
    },
};

function getComponentName({data, key}) {
    if (data.componentName){
        return data.componentName;
    }
    if (key === "submit"){
        return "Button";
    }
    let componentName = "TextField";
    if (data.schemaType){
        switch(data.schemaType) {
            case "String":
                componentName = "TextField";
                break;
            case "Number":
                componentName = "NumberField";
                break;
            case "Boolean":
                componentName = "Checkbox";
                break;
            case "Float":
                componentName = "NumberField";
                break;
            case "MongoID":
                componentName = "Posts";
                break;
            default:
                componentName = "TextField";
        }
    }
    if (typeof data.options === "object" && data.options.length){
        componentName = "Select"
    }
    return componentName;
}

function generatePropsAndSelectComponent({components, formData, key, onSubmit, onChange, submitRef}) {

    const data = {...formData[key]};

    const componentName = getComponentName({data, key});

    const Component = components[componentName]?.Component || TextField;
    const defaultProps = {...components[componentName]?.props || {}};

    if (componentName === "Button" && data.label && typeof data.children == "undefined"){
        data.children = data.label;
        delete data.label;
    }

    const props = Object.keys(defaultProps).reduce(function (a, key) {
        a[key] = (typeof data[key] !== "undefined") ? data[key] : defaultProps[key];
        return a;
    }, {});

    if (props.type === "submit") {
        props.onClick = onSubmit;
        props.effect = ({actions})=>{submitRef.actions = actions}
    } else if (componentName) {
        props.onChange = function (e) {
            return onChange(e, key);
        };
    }

    if (
        typeof defaultProps.defaultValue !== "undefined" &&
        typeof data.defaultValue === "undefined" &&
        typeof data.default !== "undefined"
    ){
        props.defaultValue = data.default;
    }

    if (!props.id){
        props.id = (key.startsWith("record.")) ? key.split("record.")[1] : key
    }

    if (!props.label){
        props.label = key.slice(0,1).toUpperCase() + key.slice(1)
    }

    if (data.required && !data.requiredAsteriskDisableShowOnLabel && props.label && typeof props.label === "string" && props.label.slice(-2) !== " *") {
        props.label = props.label + " *";
    }

    props.key = key;

    return {props, Component};

}

function FormItem(props) {

    const {formData, fKey, components, onSubmit, onChange, submitRef, post, isAdmin, isAuthorOrAdmin} = props;

    const writeCondition = formData[fKey].writeCondition;
    const canWriteAdmin = (writeCondition === "admin");
    const canWriteAuthorOrAdmin = (post?._id && !canWriteAdmin);
    const canWriteEverybody = (!post?._id && !canWriteAdmin);

    const go = (
        (canWriteEverybody) ||
        (canWriteAuthorOrAdmin && isAuthorOrAdmin) ||
        (canWriteAdmin && isAdmin)
    );

    if (!formData[fKey].hidden && go) {

        const componentAndProps = generatePropsAndSelectComponent({
            components,
            formData,
            key: fKey,
            onSubmit,
            onChange,
            submitRef
        });

        const Component = componentAndProps.Component;
        const componentProps = componentAndProps.props;

        return <Component {...componentProps} />

    }

    return null;

}

function Container(props) {
    const {container = {}, ...rest} = props;

    return (
        <>{(container.items) ?
            (Object.keys(container.items).length) ? <div key={container.key} className={style.container}>
                {(container.label) ? <div className={style.containerLabel}>{container.label}</div> : null}
                {Object.keys(container.items).map((key)=>{
                    const fKey = (container.key) ? container.key+"."+key : key;
                    return (
                        <Container key={fKey} {...{...rest, container:container.items[key], fKey}} />
                    )
                })}
            </div> : null
            :
            <FormItem key={container.key} {...rest} />
        }</>
    )
}

class Form extends React.Component {
    constructor(props, context) {

        super(props, context);

        this.state = {
            snackMessage: "",
            formData: {...copyObject(props.formData, {keep: ["label", "startIcon"]}) || {}},
        };

        if (props.initialState){
            this.state = props.initialState(this.state, props, context);
        }

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);

        this.addStyle = this.addStyle.bind(this);
        this.removeStyle = null;
        this.submitRef = {};
        this.progress = false;
        this.done = false;
        this.isUnmounted = false;

        const {wapp} = context;
        if (wapp.target === "node"){
            this.addStyle();
        }

    }
    componentDidUpdate(prevProps) {
        if (this.props.formData !== prevProps.formData) {
            this.setState({
                formData: {...copyObject(this.props.formData, {keep: ["label", "startIcon"]}) || {}}
            })
        }
    }
    componentDidMount() {
        this.addStyle();
        this.isUnmounted = false;
    }
    componentWillUnmount() {
        if (this.removeStyle){
            this.removeStyle();
        }
        this.isUnmounted = true;
    }
    addStyle() {
        if (this.removeStyle){
            this.removeStyle();
        }
        const {wapp} = this.context;
        this.removeStyle = wapp.styles.add(style);
    }
    onChange(e, key) {
        const {onChange} = this.props;
        const formData = this.state.formData;
        const data = {...formData[key]};

        if (e.target.value !== data.value) {
            data.value = e.target.value;
            data.helperText = "";
            data.error = false;
            data.errors = [];
            const newFormData = {
                ...formData,
                [key] : data
            };
            this.setState({
                formData: {
                    ...formData,
                    [key] : data
                },
                snackMessage: ""
            });
            if (onChange){
                onChange(e, key, newFormData)
            }
        }
    }
    handleCloseSnackbar(/*e, reason*/) {
        if (this.state.snackMessage) {
            this.setState({
                snackMessage: ""
            })
        }
    }
    async onSubmit(e) {

        e.preventDefault();

        const progress = this.progress;
        const done = this.done;

        if (progress || done){
            return null;
        }

        this.progress = true;

        if (this.submitRef.actions) {
            await this.submitRef.actions.setProgress(true);
        }

        const {successMessage} = this.props;

        const {
            onSubmit = async function onSubmit(/*e, formData*/) {
                return new Promise(async function (resolve) {
                    return resolve(response);
                })
            }
        } = this.props;

        const newState = {...this.state};
        newState.formData = {...newState.formData};

        const props = this.props;

        const {
            accountContext,
            postContext
        } = this.props;

        const user = postContext.user || accountContext.user;
        const post = postContext.post || accountContext.user;
        const author = post?._author?._id || post?._author;
        const isAuthor = (user?._id && user?._id === author);
        const isAdmin = user && user._status_isFeatured;
        const isAuthorOrAdmin = !!(isAuthor || isAdmin);

        const {
            formData,
            snackMessage
        } = newState;

        const sendData = Object.keys(formData).reduce(function (a, key) {

            const writeCondition = formData[key].writeCondition;
            const canWriteAdmin = (writeCondition === "admin");
            const canWriteAuthorOrAdmin = (post?._id && !canWriteAdmin);
            const canWriteEverybody = (!post?._id && !canWriteAdmin);

            const go = (
                (canWriteEverybody) ||
                (canWriteAuthorOrAdmin && isAuthorOrAdmin) ||
                (canWriteAdmin && isAdmin)
            );

            if (go){

                const data = copyObject(formData[key], {keep: ["label", "startIcon"]});
                const componentName = getComponentName({data, key});
                if (key !== "submit" && data.type !== "submit") {

                    let parent = a;
                    let lastKey = key;

                    if (key.match(".")){
                        const names = key.split(".");
                        if (names.length > 1) {
                            names.forEach(function (name, i) {
                                if (!parent[name]){
                                    parent[name] = {};
                                }
                                if (i < names.length-1) {
                                    parent = parent[name];
                                }
                                lastKey = name;
                            })
                        }
                    }

                    if (componentName.match("Posts") && data.multiple && data.value && !data.value.length){
                        data.value = null;
                    }

                    if (componentName === "Checkbox" && !data.value) {
                        data.value = false;
                    }

                    parent[lastKey] = (
                        data.value ||
                        (data.value === 0 && data.schemaType === "Number") ||
                        (data.value === 0 && data.schemaType === "Float") ||
                        (data.value === false && data.schemaType === "Boolean")
                    ) ?
                        data.value :
                        (data.required) ?
                            props.formData[key].default
                            : null;

                    if (componentName.match("Posts")) {
                        if (parent[lastKey]){
                            if (typeof parent[lastKey] === "object" && typeof parent[lastKey].length === "number"){
                                parent[lastKey] = parent[lastKey].map((p)=>p._id)
                            } else if (parent[lastKey]._id){
                                parent[lastKey] = parent[lastKey]._id;
                            }
                        }
                    }

                }

            }

            return a;

        }, {});

        const response = await onSubmit(e, sendData);

        if ((response && response.error) || (response && response.errors)){

            const message = (response.error?.message) || (response.errors && response.errors[0] && response.errors[0].message) || "Error";
            const errors = response.error?.errors || response.errors;

            let shouldSetState = false;

            if (errors && errors.length){

                Object.keys(formData).forEach(function (path) {
                    if (formData[path].errors) {
                        formData[path].errors = [];
                    }
                });

                errors.forEach(function (error) {
                    const message = error.message;
                    const path = Array.isArray(error.path) ? error.path.slice(1).join(".") : error.path;
                    const dotParts = (typeof path === "string" && path) ? path.split(".") : [];
                    if (!isNaN(Number(dotParts[dotParts.length-1])) && !formData[path]){
                        const errorPath = Number(dotParts[dotParts.length-1]);
                        const formPath = path.split("."+dotParts[dotParts.length-1])[0];
                        if (formData[formPath]){
                            if (!formData[formPath].errors) {
                                formData[formPath].errors = [];
                            }
                            formData[formPath].errors.push({path: errorPath, message});
                            if (!formData[formPath].error){
                                formData[formPath].error = true;
                            }
                            if (!formData[formPath].helperText) {
                                formData[formPath].helperText = message;
                            }
                            shouldSetState = true;
                        }
                    } else if (formData[path] && formData[path].helperText !== message){
                        formData[path].helperText = message;
                        formData[path].error = !!(formData[path].helperText);
                        shouldSetState = true;
                    }
                })
            }

            if (message && snackMessage !== message){
                newState.snackMessage = message;
                shouldSetState = true;
            }

            if (shouldSetState){
                await this.setState(newState)
            }

            if (this.submitRef.actions) {
                await this.submitRef.actions.setProgress(false);
            }

            await new Promise((resolve)=>setTimeout(resolve, 1000));
            this.progress = false;

        } else if (response){

            this.done = true;
            this.progress = false;

            if (!this.isUnmounted) {

                if (this.submitRef.actions) {
                    await this.submitRef.actions.setProgress(false);
                    await this.submitRef.actions.setDisabled(true);
                    await this.submitRef.actions.setDone(true);
                }

                if (successMessage) {
                    newState.snackMessage = successMessage;
                    await this.setState(newState);
                }

            }

        }

        return response;

    }
    async setNewFormData(formData) {
        await this.setState({formData: {...copyObject(formData, {keep: ["label", "startIcon"]}) || {}}})
    }
    render() {

        const onSubmit = this.onSubmit;
        const onChange = this.onChange;
        const handleCloseSnackbar = this.handleCloseSnackbar;

        const {
            formData,
            snackMessage
        } = this.state;

        const {
            components = defaultComponents,
            postContext,
            accountContext,
            appContext
        } = this.props;

        //const {materialStyle} = this.props;

        const user = postContext.user || accountContext.user;
        const post = postContext.post || accountContext.user;
        const author = post?._author?._id || post?._author;
        const isAuthor = (user?._id && user?._id === author);
        const isAdmin = user && user._status_isFeatured;
        const isAuthorOrAdmin = !!(isAuthor || isAdmin);

        const root = {
            key: "",
            items: {}
        };

        Object.keys(formData).sort((a, b)=>{
            const aO = formData[a].order || 0;
            const bO = formData[b].order || 0;
            if (a === "submit") {
                return 1;
            }
            return (aO > bO) ? 1 : (aO < bO) ? -1 : 0;
        }).forEach((key)=> {
                const dotParts = key.split(".");
                if (dotParts.length) {
                    const name = postContext.parentRoute ? postContext.name : accountContext.name;
                    dotParts.reduce((o, part, i)=>{
                        if (!o[part] && i !== dotParts.length-1){
                            o[part] = {
                                key: dotParts.slice(0,i+1).join("."),
                                label: appContext.labels[name+"ContainerLabel_"+dotParts.slice(0,i+1).join("_")],
                                items: {}
                            }
                        } else if (i === dotParts.length-1){
                            if (!formData[key].hidden) {
                                o[part] = formData[key]
                            }
                        }
                        return (o[part] && o[part].items) ? o[part].items : o[part];
                    }, root.items)
                }
            }
        );

        const submitRef = this.submitRef;

        return (
            <div className={style.formContainer}>
                <form className={style.form}
                      autoComplete={"off"}
                      noValidate
                      onSubmit={onSubmit}
                >
                    <Container container={root} {...{formData, components, onSubmit, onChange, submitRef, isAuthorOrAdmin, isAdmin, post}}/>
                </form>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={!!(snackMessage)}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={snackMessage}
                />

            </div>
        )
    }
}

Form.contextType = WappContext;

const FormWithContext = React.forwardRef((props, ref) => {

    const postContext = useContext(PostContext);
    const accountContext = useContext(AccountContext);
    const appContext = useContext(AppContext);

    return (
        <Form {...props} postContext={postContext} accountContext={accountContext} appContext={appContext} ref={ref}/>
    )
});

const WappComponent = withWapp(FormWithContext);

export default withMaterialStyles(materialStyle, WappComponent);
