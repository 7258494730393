// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8Pep8{}\n\n.L2iMS{}\n\n.tNoN3{}\n\n.t99MN{}\n\n@media (max-width:799px) {\n\n    .tNoN3 {\n        min-height: 100%;\n    }\n\n    .tNoN3 {\n        border-radius: 0;\n        max-width: 100%;\n        width: 100%;\n        margin: 0;\n        max-height: 100%;\n    }\n\n    .t99MN{\n        padding: 16px;\n    }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"singup": "_8Pep8",
	"dialog": "L2iMS",
	"dialogPaper": "tNoN3",
	"dialogContent": "t99MN"
};
module.exports = ___CSS_LOADER_EXPORT___;
