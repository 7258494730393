export default function ({data}) {

    const languages = data.settings?.basic_settings?.languages || ["lang_1"];

    function getPropertiesByLanguages(p = {}) {
        const {schemaType = "String", propsWithKeys = {}, ...props} = p;
        return languages.reduce((o, value)=>{
            o[value] = {schemaType, ...props, ...(propsWithKeys[value]) ? propsWithKeys[value] : {}};
            return o;
        }, {})
    }

    function getLanguageObject(p = {}) {
        return {
            schemaType: "Object",
            properties: getPropertiesByLanguages(p)
        }
    }

    function getColorSchemeObject(p = {}) {
        const {schemaType = "String"} = p;
        return {
            schemaType: "Object",
            properties: {
                light: {
                    schemaType,
                },
                dark: {
                    schemaType,
                }
            }
        }
    }

    function getLogoObject() {
        return {
            "schemaType": "Object",
            "properties": {
                "disable": {
                    "schemaType": "boolean",
                    "componentName": "Checkbox",
                    "label": "disable"
                },
                "image_url": getColorSchemeObject(),
            }
        }
    }

    function getGalleryObject() {
        return {
            "schemaType": "Array",
            "add": async ({props}) => {
                const {setValue, value = []} = props;
                value.push({
                    "image_url": ""
                })
                await setValue(value, true);
            },
            "items": {
                "schemaType": "Object",
                "enableDelete": true,
                "properties": {
                    "image_url": {
                        "schemaType": "String"
                    }
                }
            }
        }
    }

    function getLayersObject() {
        return {
            "schemaType": "Array",
            "add": async ({props}) => {
                const {setValue, value = []} = props;
                value.push([
                    {
                        "image_url_day": "https://configurator.studioarkitekter.com/demo/minimal_kitchen.jpg",
                        "image_url_night": "https://configurator.studioarkitekter.com/demo/minimal_kitchen.jpg?night"
                    },
                ])
                await setValue(value, true);
            },
            "items": {
                "schemaType": "Object",
                "enableDelete": true,
                "properties": {
                    "image_url_day": {
                        "schemaType": "String"
                    },
                    "image_url_night": {
                        "schemaType": "String"
                    }
                }
            }
        }
    }

    const textAreaProps = {
        multiline: true,
        rows: 4,
        minRows: 4,
        maxRows: 20,
    }

    const generateLabelForArrayItems = ({props}) => {
        const {value, propertyKey} = props;
        if (value && value.name && Object.keys(value.name)[0]){
            const key = value.key || propertyKey;
            return value.name[Object.keys(value.name)[0]] + " ["+key+"]";
        }
        return (propertyKey.slice) ? propertyKey.slice(0,1).toUpperCase() + propertyKey.slice(1) : propertyKey.toString();
    }

    const subscribePropertiesForLabelForArrayItems = ({schemaFormContext, props, setLabel}) => {

        const {stateManager, rootData} = schemaFormContext;
        const {propertyTreeString, value, propertyKey } = props;

        const languages = rootData.settings?.basic_settings?.languages || ["lang_1"];

        return stateManager.subscribe(async (state, {type, payload}) => {
            if (type === "SET_LABEL"){
                const changingItemPropertyTreeString = payload.name;
                if (changingItemPropertyTreeString === propertyTreeString+".name."+languages[0]){
                    const key = value.key || propertyKey;
                    await setLabel(payload.value + " ["+key+"]");
                }
            }
        })
    }

    const onChangeForName = ({schemaFormContext, props}) =>{

        const {value, propertyTreeString} = props;
        const {stateManager} = schemaFormContext;

        const state = stateManager.getState();
        if (!state.label || state.label && state.label[propertyTreeString] !== value) {
            stateManager.dispatch(stateManager.runAction("label", {name: propertyTreeString, value: value}));
        }
    }

    return {
        "schemaType": "Object",
        "label": "Editor",
        "properties": {
            "settings": {
                "schemaType": "Object",
                "properties": {
                    "basic_settings": {
                        "schemaType": "Object",
                        "properties": {
                            "name": getLanguageObject(),
                            "short_name": getLanguageObject(),
                            "description": getLanguageObject({...textAreaProps}),
                            "logo": getLogoObject(),
                            "home_logo_icon": getLogoObject(),
                            "home_logo_text": getLogoObject(),
                            "footer_logo": getLogoObject(),
                            "footer_copyright": getLanguageObject(),
                            "currency": getLanguageObject(),
                            "currency_value": getLanguageObject({
                                schemaType: "Number",
                                validations: [{name: "min", value: 0, type: "number", enableNullishValue: true}],
                                propsWithKeys: {[languages[0]] : {validations: [{name: "min", value: 0, type: "number"}]}},
                            }),
                            "languages": {
                                "schemaType": "Array",
                                "add": async ({schemaFormContext, props}) => {
                                    const {setValue, value = []} = props;
                                    const key = "lang_"+(value.length+1);
                                    value.push(key);
                                    await setValue(value, true);

                                    const newSchema = schemaFormContext.props.additionalProperties.generateSchema({schemaFormContext});
                                    await schemaFormContext.setSchema(newSchema);
                                },
                                "items": {
                                    "schemaType": "String",
                                    "label": "Language code",
                                    "disabled": true,
                                    "enableDelete": ({props})=>{
                                        return (props.parentValue && props.parentValue.length) ? props.propertyKey && props.propertyKey === (props.parentValue.length - 1) : false;
                                    },
                                    "onDelete": async ({schemaFormContext}) => {
                                        const {props, setSchema} = schemaFormContext;
                                        const newSchema = props.additionalProperties.generateSchema({schemaFormContext});
                                        await setSchema(newSchema);
                                    },
                                    "onChange": async ({schemaFormContext}) =>{
                                        const {props, setSchema} = schemaFormContext;
                                        const newSchema = props.additionalProperties.generateSchema({schemaFormContext});
                                        await setSchema(newSchema);
                                    }
                                }
                            }
                        }
                    },

                    "project_settings": {
                        "schemaType": "Object",
                        "properties": {
                            "project_address": getLanguageObject(),
                            "project_contact_person": getLanguageObject(),
                            "project_contact_phone": getLanguageObject(),
                            "project_contact_email": getLanguageObject(),
                            "gallery": getGalleryObject()
                        }
                    },

                    "models_settings": {
                        "schemaType": "Object",
                        "properties": {
                            "models_name": getLanguageObject(),
                            "models_description": getLanguageObject({...textAreaProps}),
                        }
                    },

                    "configurations_settings": {
                        "schemaType": "Object",
                        "properties": {
                            "configurations_name": getLanguageObject(),
                            "configurations_description": getLanguageObject({...textAreaProps}),
                        }
                    },

                    "summary_settings": {
                        "schemaType": "Object",
                        "properties": {
                            "summary_name": getLanguageObject(),
                            "summary_description": getLanguageObject(),
                            "summary_founding_name": getLanguageObject(),
                            "summary_founding_description": getLanguageObject(),
                            "summary_contact_name": getLanguageObject(),
                            "summary_save_options_name": getLanguageObject(),
                        }
                    },

                    "context": {
                        "schemaType": "Object",
                        "properties": {
                            "menus": {
                                "schemaType": "Object",
                                "properties": {
                                    "startConfiguratorMenu": getLanguageObject(),
                                    "descriptionMenu": getLanguageObject(),
                                    "modelsMenu": getLanguageObject(),
                                    "collectionsMenu": getLanguageObject(),
                                    "summaryMenu": getLanguageObject(),
                                    "collectionsMenuCustomSpan": getLanguageObject(),
                                }
                            },
                            "messages": {
                                "schemaType": "Object",
                                "properties": {
                                    "notFoundModel": getLanguageObject(),
                                    "notFoundCollection": getLanguageObject(),
                                    "notFoundAnswer": getLanguageObject(),
                                }
                            },
                            "labels": {
                                "schemaType": "Object",
                                "properties": {
                                    "nextLabel": getLanguageObject(),
                                    "prevLabel": getLanguageObject(),
                                    "summaryLabel": getLanguageObject(),
                                    "modelsLabel": getLanguageObject(),
                                    "collectionsLabel": getLanguageObject(),
                                    "copyUrlToClipboardLabel": getLanguageObject(),
                                    "printLabel": getLanguageObject(),
                                    "selectLabel": getLanguageObject(),
                                    "selectedLabel": getLanguageObject(),
                                    "languages": getLanguageObject(),
                                }
                            }
                        }
                    }
                }
            },
            "models": {
                "schemaType": "Array",
                "add": async ({props}) => {
                    const {setValue, value = []} = props;
                    const key = Date.now()+"_model";
                    value.push({
                        "key": key,
                        "name": {
                            "lang_1": "A new model " + (value.length+1),
                            "lang_2": "A new model " + (value.length+1)
                        },
                        "description": {
                            "lang_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
                            "lang_2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore."
                        },
                        "content": {
                            "lang_1": "Long text lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Long text lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                            "lang_2": "Long text lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Long text lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                        },
                        "price": {
                            "lang_1": 89000000
                        },
                        "gallery": [
                            {
                                "image_url": "https://configurator.studioarkitekter.com/demo/loft_gallery_0.jpg"
                            },
                            {
                                "image_url": "https://configurator.studioarkitekter.com/demo/floorplan.jpg"
                            }
                        ],
                        "gallery_index_for_summary": 1
                    })
                    await setValue(value, true);
                },
                "items": {
                    "schemaType": "Object",
                    "generateLabel": generateLabelForArrayItems,
                    "useEffectForLabel": subscribePropertiesForLabelForArrayItems,
                    "enableDelete": ({props})=>{
                        return (props.parentValue && props.parentValue.length) ? props.propertyKey && props.propertyKey > 0 : false;
                    },
                    "properties": {
                        "key": {
                            "schemaType": "String",
                            "disabled": true,
                        },
                        "name": getLanguageObject({onChange: onChangeForName}),
                        "description": getLanguageObject({...textAreaProps}),
                        "content": getLanguageObject({...textAreaProps}),
                        "price": getLanguageObject({
                            schemaType: "Number",
                            validations: [{name: "min", value: 0, type: "number", enableNullishValue: true}],
                            propsWithKeys: {[languages[0]] : {validations: [{name: "min", value: 0, type: "number"}]}},
                        }),
                        "gallery": getGalleryObject(),
                        "gallery_index_for_summary": {
                            "schemaType": "Number",
                            "validations": [{"name": "min", "value": 0, "type": "number", "enableNullishValue": true}]
                        },
                        "collections": {
                            "schemaType": "Array",
                            "add": async ({props, schemaFormContext}) => {
                                const {setValue, value = []} = props;
                                const key = Date.now()+"_collection";
                                value.push({
                                    "key": key,
                                    "name": {
                                        "lang_1": "A new collection " + (value.length+1),
                                        "lang_2": "A new collection " + (value.length+1)
                                    },
                                    "description": {
                                        "lang_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
                                        "lang_2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore."
                                    }
                                })
                                await setValue(value, true);

                                const newSchema = schemaFormContext.props.additionalProperties.generateSchema({schemaFormContext});
                                await schemaFormContext.setSchema(newSchema);
                            },
                            "items": {
                                "schemaType": "Object",
                                "generateLabel": generateLabelForArrayItems,
                                "useEffectForLabel": subscribePropertiesForLabelForArrayItems,
                                "enableDelete": ({props})=>{
                                    return (props.parentValue && props.parentValue.length) ? props.propertyKey && props.propertyKey > 0 : false;
                                },
                                "onDelete": async ({schemaFormContext}) => {
                                    const {props, setSchema} = schemaFormContext;
                                    const newSchema = props.additionalProperties.generateSchema({schemaFormContext});
                                    await setSchema(newSchema);
                                },
                                "properties": {
                                    "key": {
                                        "schemaType": "String",
                                        "disabled": true,
                                    },
                                    "name": getLanguageObject({onChange: onChangeForName}),
                                    "description": getLanguageObject({...textAreaProps}),
                                }
                            }
                        },
                        "steps": {
                            "schemaType": "Array",
                            "add": async ({props}) => {
                                const {setValue, value = []} = props;
                                const key = Date.now()+"_step";
                                value.push({
                                    "key": key,
                                    "name": {
                                        "lang_1": "A new step " + (value.length+1),
                                        "lang_2": "A new step " + (value.length+1)
                                    },
                                    "description": {
                                        "lang_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
                                        "lang_2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
                                    },
                                    "layer": [
                                        {
                                            "image_url_day": "https://configurator.studioarkitekter.com/demo/minimal_kitchen.jpg",
                                            "image_url_night": "https://configurator.studioarkitekter.com/demo/minimal_kitchen.jpg?night"
                                        },
                                        {
                                            "image_url_day": "https://configurator.studioarkitekter.com/demo/minimal_kitchen.jpg",
                                            "image_url_night": "https://configurator.studioarkitekter.com/demo/minimal_kitchen.jpg?night"
                                        }
                                    ],
                                    "options": [
                                        {
                                            "key": Date.now()+"_question",
                                            "name": {
                                                "lang_1": "A new question 1",
                                                "lang_2": "A new question 1",
                                            },
                                            "description": {
                                                "lang_1": "Lorem ipsum dolor",
                                                "lang_2": "Lorem ipsum dolor"
                                            },
                                            "options": [{
                                                "key": Date.now()+"_answer"+"_A",
                                                "name": {
                                                    "lang_1": "A new answer 1",
                                                    "lang_2": "A new answer 1"
                                                },
                                                "description": {
                                                    "lang_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
                                                    "lang_2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore."
                                                },
                                                "price": {
                                                    "lang_1": 0
                                                },
                                                "collections": [],
                                                "layer": [
                                                    {
                                                        "image_url_day": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_0.png",
                                                        "image_url_night": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_0.png?night"
                                                    },
                                                    {
                                                        "image_url_day": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_0.png",
                                                        "image_url_night": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_0.png?night"
                                                    }
                                                ],
                                                "gallery": [
                                                    {
                                                        "image_url": "https://configurator.studioarkitekter.com/demo/thumb_green.jpg"
                                                    },
                                                    {
                                                        "image_url": "https://configurator.studioarkitekter.com/demo/thumb_black.jpg"
                                                    }
                                                ]
                                            }]
                                        }
                                    ]
                                })
                                await setValue(value, true);
                            },
                            "items": {
                                "schemaType": "Object",
                                "generateLabel": generateLabelForArrayItems,
                                "useEffectForLabel": subscribePropertiesForLabelForArrayItems,
                                "enableDelete": true,
                                "properties": {
                                    "key": {
                                        "schemaType": "String",
                                        "disabled": true,
                                    },
                                    "name": getLanguageObject({onChange: onChangeForName}),
                                    "description": getLanguageObject({...textAreaProps}),
                                    "layer": getLayersObject(),
                                    "options": {
                                        "schemaType": "Array",
                                        "add": async ({props}) => {
                                            const {setValue, value = []} = props;
                                            const key = Date.now()+"_question";
                                            value.push({
                                                "key": key,
                                                "name": {
                                                    "lang_1": "A new question " + (value.length + 1),
                                                    "lang_2": "A new question " + (value.length + 1),
                                                },
                                                "description": {
                                                    "lang_1": "Lorem ipsum dolor",
                                                    "lang_2": "Lorem ipsum dolor"
                                                },
                                                "options": [{
                                                    "key": Date.now()+"_answer"+"_A",
                                                    "name": {
                                                        "lang_1": "A new answer 1",
                                                        "lang_2": "A new answer 1"
                                                    },
                                                    "description": {
                                                        "lang_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
                                                        "lang_2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore."
                                                    },
                                                    "price": {
                                                        "lang_1": 0
                                                    },
                                                    "collections": [],
                                                    "layer": [
                                                        {
                                                            "image_url_day": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_0.png",
                                                            "image_url_night": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_0.png?night"
                                                        },
                                                        {
                                                            "image_url_day": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_0.png",
                                                            "image_url_night": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_0.png?night"
                                                        }
                                                    ],
                                                    "gallery": [
                                                        {
                                                            "image_url": "https://configurator.studioarkitekter.com/demo/thumb_green.jpg"
                                                        },
                                                        {
                                                            "image_url": "https://configurator.studioarkitekter.com/demo/thumb_black.jpg"
                                                        }
                                                    ]
                                                }]
                                            })
                                            await setValue(value, true);
                                        },
                                        "items": {
                                            "schemaType": "Object",
                                            "generateLabel": generateLabelForArrayItems,
                                            "useEffectForLabel": subscribePropertiesForLabelForArrayItems,
                                            "enableDelete": ({props})=>{
                                                return (props.parentValue && props.parentValue.length) ? props.propertyKey && props.propertyKey > 0 : false;
                                            },
                                            "properties": {
                                                "key": {
                                                    "schemaType": "String",
                                                    "disabled": true,
                                                },
                                                "name": getLanguageObject({onChange: onChangeForName}),
                                                "description": getLanguageObject({...textAreaProps}),
                                                "options": {
                                                    "schemaType": "Array",
                                                    "add": async ({props}) => {
                                                        const {setValue, value = []} = props;
                                                        const key = Date.now()+"_answer";

                                                        const answers = [
                                                            {
                                                                "key": key+"_A",
                                                                "name": {
                                                                    "lang_1": "A new answer " + (value.length+1),
                                                                    "lang_2": "A new answer " + (value.length+1)
                                                                },
                                                                "description": {
                                                                    "lang_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
                                                                    "lang_2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore."
                                                                },
                                                                "price": {
                                                                    "lang_1": 0
                                                                },
                                                                "collections": [],
                                                                "layer": [
                                                                    {
                                                                        "image_url_day": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_0.png",
                                                                        "image_url_night": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_0.png?night"
                                                                    },
                                                                    {
                                                                        "image_url_day": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_0.png",
                                                                        "image_url_night": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_0.png?night"
                                                                    }
                                                                ],
                                                                "gallery": [
                                                                    {
                                                                        "image_url": "https://configurator.studioarkitekter.com/demo/thumb_green.jpg"
                                                                    },
                                                                    {
                                                                        "image_url": "https://configurator.studioarkitekter.com/demo/thumb_black.jpg"
                                                                    }
                                                                ]
                                                            },
                                                            {
                                                                "key": key+"_B",
                                                                "name": {
                                                                    "lang_1": "A new answer " + (value.length+1),
                                                                    "lang_2": "A new answer " + (value.length+1)
                                                                },
                                                                "description": {
                                                                    "lang_1": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
                                                                    "lang_2": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore."
                                                                },
                                                                "price": {
                                                                    "lang_1": 150000
                                                                },
                                                                "collections": [],
                                                                "layer": [
                                                                    {
                                                                        "image_url_day": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_1.png",
                                                                        "image_url_night": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_1.png?night"
                                                                    }, {
                                                                        "image_url_day": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_1.png",
                                                                        "image_url_night": "https://configurator.studioarkitekter.com/demo/minimal_kitchen_pavement_1.png?night"
                                                                    }
                                                                ],
                                                                "gallery": [
                                                                    {
                                                                        "image_url": "https://configurator.studioarkitekter.com/demo/thumb_pink.jpg"
                                                                    },
                                                                    {
                                                                        "image_url": "https://configurator.studioarkitekter.com/demo/thumb_black.jpg"
                                                                    }
                                                                ]
                                                            },
                                                        ]

                                                        const even = (value.length % 2 === 0);

                                                        value.push((even) ? answers[0] : answers[1])
                                                        await setValue(value, true);
                                                    },
                                                    "items": {
                                                        "schemaType": "Object",
                                                        "generateLabel": generateLabelForArrayItems,
                                                        "useEffectForLabel": subscribePropertiesForLabelForArrayItems,
                                                        "enableDelete": ({props})=>{
                                                            return (props.parentValue && props.parentValue.length) ? props.propertyKey && props.propertyKey > 0 : false;
                                                        },
                                                        "properties": {
                                                            "key": {
                                                                "schemaType": "String",
                                                                "disabled": true,
                                                            },
                                                            "name": getLanguageObject({onChange: onChangeForName}),
                                                            "description": getLanguageObject({...textAreaProps}),
                                                            "pdf_url": getLanguageObject({
                                                                validations: [{name: "urlOrEmpty"}],
                                                            }),
                                                            "price": getLanguageObject({
                                                                schemaType: "Number",
                                                                validations: [{name: "min", value: 0, type: "number", enableNullishValue: true}],
                                                            }),
                                                            "gallery": getGalleryObject(),
                                                            "layer": getLayersObject(),
                                                            "collections": {
                                                                "schemaType": "Array",
                                                                "componentName": "Select",
                                                                "multiple": true,
                                                                "selectOptions": ({schemaFormContext, props})=> {
                                                                    const {rootData} = schemaFormContext;
                                                                    const {propertyTreeString, value} = props;
                                                                    const modelIndex = propertyTreeString.split(".")[2];
                                                                    const model = rootData.models[modelIndex];
                                                                    const collectionKeys = model.collections.map((collection)=>collection.key);
                                                                    const languages = rootData.settings?.basic_settings?.languages || ["lang_1"];
                                                                    const collectionKeysOption = model.collections.map((collection)=>{
                                                                        const label = (collection.name && collection.name[languages[0]]) ? collection.name[languages[0]] + " ["+collection.key+"]" : collection.key;
                                                                        return {label, value: collection.key}
                                                                    });
                                                                    const invalidVales = (value && value.length) ? value.filter((key)=>collectionKeys.indexOf(key) === -1).map((key)=>{return {label: key, value: key}}) : [];
                                                                    return [...collectionKeysOption, ...invalidVales];
                                                                },
                                                                "validations": ({schemaFormContext, props})=> {
                                                                    const {rootData} = schemaFormContext;
                                                                    const {propertyTreeString} = props;
                                                                    const modelIndex = propertyTreeString.split(".")[2];
                                                                    const model = rootData.models[modelIndex];
                                                                    const collectionKeys = (model?.collections) ? model.collections.map((collection)=>{return collection.key}) : [];
                                                                    return [{name: "arrayIn", value: collectionKeys}];
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
