import React from "react";

import PlayIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import PauseIcon from "@material-ui/icons/Pause";
import IconButton from "@material-ui/core/IconButton";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeOnIcon from "@material-ui/icons/VolumeUp";

import clsx from "clsx";

class Timer extends React.Component {
    constructor(props) {
        super(props);

        const {duration, currentTime, isPlaying} = this.props;

        this.isUnmounted = false;

        this.state = {
            duration: duration || 0,
            currentTime: currentTime || 0,
            isPlaying: isPlaying || false,
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        const watchProperties = ["duration", "isPlaying"];
        const nextState = watchProperties.reduce((nextState, propertyName)=>{
            if (nextProps[propertyName] !== prevState[propertyName]) {
                nextState[propertyName] = nextProps[propertyName];
            }
            return nextState;
        }, {});
        return (Object.keys(nextState).length) ? nextState : null;
    }
    componentDidMount() {
        this.stop();
        this.start();
    }
    componentWillUnmount() {
        this.stop();
        this.isUnmounted = true;
    }


    timeText = (time) => {
        const m = (time) ? Math.floor(time / 60) : 0;
        let s = (time) ? Math.floor(time - (m*60)) : 0;
        if (s < 10) {s = "0"+s;}
        return m + ":" + s;
    };
    currentTimeText = (time) => {
        const currentTime = this.state.currentTime || time;
        return this.timeText(currentTime);
    };
    currentStyle = ({duration, currentTime}) => {
        return {width:(duration && currentTime < duration) ? ((currentTime/duration)*100) + "%" : {}}
    };
    plus = (ms = 1000) => {

        if (this.state.isPlaying) {

            const currentTime = this.state.currentTime || 0;
            const duration = this.state.duration || 0;

            const s = ms / 1000;
            const newCurrentTime = currentTime + s;

            if (!this.isUnmounted) {
                this.setState({
                    currentTime: (newCurrentTime > duration) ? duration : newCurrentTime
                });
            }

        }
    };
    stop = () => {
        if (this.interval) {clearInterval(this.interval);}
    };
    start = () => {
        if (this.interval) {clearInterval(this.interval);}
        const plus = this.plus;
        this.interval = setInterval(()=>plus(25), 25)
    };
    setCurrentTime(currentTime){
        this.setState({
            currentTime
        })
    };
    render() {

        const {style} = this.props;

        return (
            <div className={style.timerContainer}>
                <div className={style.currentTimeBox}>
                    <div
                        className={style.currentTime}
                        style={this.currentStyle({duration: this.state.duration, currentTime: this.state.currentTime})}
                    />
                </div>
                <div className={style.currentTimeText}>
                    {this.timeText(this.state.currentTime) +" / "+ this.timeText(this.state.duration)}
                </div>
            </div>
        )

    }
}

export default class Controls extends React.Component {
    constructor(props) {

        super(props);

        const {title, isPlaying, currentTime, duration, muted} = this.props;

        this.refElements = {};

        this.isUnmounted = false;

        this.state = {
            title: title || "",
            isPlaying: isPlaying || false,
            currentTime: currentTime || 0,
            duration: duration || 0,
            isActive: false,
            muted: muted || false
        };
    }
    componentDidMount() {
        this.activate();
        this.addListener();
    }
    componentWillUnmount () {
        this.isUnmounted = true;
    }

    setData = async (a) => {
        let changes = null;
        const state = this.state;
        Object.keys(a).forEach(function(d) {
            if (a[d] !== state[d]) {changes = true;}
        });
        if (changes) {
            if (!this.isUnmounted) {
                await this.setState(a);
                if (typeof a.currentTime !== "undefined" && this.refElements.timer){
                    this.refElements.timer.setCurrentTime(a.currentTime);
                }
            }
        }
    };

    addListener = () => {
        const controlsContainer = this.refElements["controlsContainer"];
        const onEnterContainer = this.onEnterContainer;
        const onLeaveContainer = this.onLeaveContainer;
        if (controlsContainer){
            this.removeListener();
            controlsContainer.addEventListener("mouseenter", onEnterContainer, false);
            controlsContainer.addEventListener("mouseleave", onLeaveContainer, false);
        }
    };
    removeListener = () => {
        const controlsContainer = this.refElements["controlsContainer"];
        const onEnterContainer = this.onEnterContainer;
        const onLeaveContainer = this.onLeaveContainer;
        if (controlsContainer){
            controlsContainer.removeEventListener("mouseenter", onEnterContainer, false);
            controlsContainer.removeEventListener("mouseleave", onLeaveContainer, false);
        }
    };
    onEnterContainer = () => {
        this.activate({disableAutoHide: true});
    };
    onLeaveContainer = () => {
        this.activate({disableAutoHide: false});
    };
    activeTimeout = (timeOut) => {
        const {activeTime = 1} = this.props;
        const t = this;
        if (this.timeout) {clearTimeout(this.timeout);}
        this.timeout = setTimeout(function() {
            if (!t.isUnmounted && t.state.isActive){
                t.setState({
                    isActive: false
                })
            }
        },timeOut || activeTime * 1000)
    };
    activate = (p = {}) => {

        const isPlaying = this.state.isPlaying;
        const {autoHide = this.props.autoHide, disableAutoHide, timeOut} = p;

        if (this.timeout) {clearTimeout(this.timeout);}

        if (!this.isUnmounted){
            if (!this.state.isActive){
                this.setState({
                    isActive: true,
                })
            }
            if ((autoHide && !disableAutoHide) || (isPlaying && !disableAutoHide)) {this.activeTimeout(timeOut);}
        }
    };
    onClick = (e, d) => {

        const {onPlay, onPause, onStop, onMute, onUnmute} = this.props;
        const {type} = d;

        this.activate();

        if (type === "play" && onPlay && onPause){
            if (this.state.isPlaying){
                onPause({
                    isPlaying: false,
                });
            } else {
                onPlay({
                    isPlaying: true,
                });
            }
        }

        if (type === "mute" && onMute){
            onMute({
                muted: true
            });
        }

        if (type === "unmute" && onUnmute){
            onUnmute({
                muted: false
            });
        }

        if (type === "stop" && onStop) {
            onStop({
                isPlaying: false,
                currentTime: 0,
            });
        }

        e.preventDefault();

    };
    setRef = (a, e) => {
        const {setRef} = this.props;
        this.refElements[a] = e;
        if (setRef) {setRef(a, e);}
    };

    render() {
        const {style, enableMute} = this.props;
        const onClick = this.onClick;

        const muted = this.state.muted;
        const isActive = this.state.isActive;
        const setRef = this.setRef;

        return (
            <div
                ref={(e)=>setRef("controlsContainer", e)}
                className={clsx({[style.mediaControlsShow]: isActive, [style.mediaControls]: true})}
            >
                <div className={style.playContainer}>
                    <IconButton
                        onClick={function(e) {onClick(e,{type:"play"})}}
                        className={style.playContainerIconButton}
                    >
                        {(this.state.isPlaying) ?
                            <PauseIcon /> :
                            <PlayIcon />
                        }
                    </IconButton>
                </div>
                <div className={style.stepContainer}>
                    <div className={style.controlsPostName}>{this.state.title}</div>
                    <div className={style.stepContainerInner}>
                        {(this.state.duration) ?
                            <Timer
                                ref={(e)=>setRef("timer", e)}
                                style={style}
                                duration={this.state.duration}
                                currentTime={this.state.currentTime}
                                isPlaying={this.state.isPlaying}
                            />:
                            null
                        }
                    </div>
                </div>
                {(enableMute) ?
                    <div className={style.stopContainer}>
                        <IconButton
                            onClick={function(e) {onClick(e,{type:(muted) ? "unmute" : "mute"})}}
                            className={style.stopContainerIconButton}
                        >
                            {(muted) ? <VolumeOffIcon /> : <VolumeOnIcon />}
                        </IconButton>
                    </div>
                    : null
                }
                {(this.state.currentTime > 0) ?
                    <div className={style.stopContainer}>
                        <IconButton
                            className={style.stopContainerIconButton}
                            onClick={function (e) {
                                onClick(e, {type: "stop"})
                            }}
                        >
                            <StopIcon/>
                        </IconButton>
                    </div>
                    : null
                }
            </div>
        )
    }
};
