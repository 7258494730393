"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var messages = {
  notFoundModel: "There are not models in this configurator",
  notFoundCollection: "There are not collections for this model",
  notFoundAnswer: "There are not options for this question"
};
var _default = messages;
exports["default"] = _default;