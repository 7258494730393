// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AQiHs {\n    position: relative;\n}\n\n.AQiHs.qZTlr {}\n\n.AQiHs.IgYuZ {\n    padding-bottom: 56%;\n    background-color: var(--divider-color);\n}\n\n.AQiHs a {\n    display: block;\n}\n\n.AQiHs .\\+K-af{\n    width: 100%;\n    height: auto;\n    vertical-align: top;\n    -webkit-transition: all 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n    -o-transition: all 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n    transition: all 1000ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n}\n\n.AQiHs.qZTlr .\\+K-af{}\n\n.AQiHs.IgYuZ .\\+K-af{\n    display: none;\n}\n\n.AQiHs > *:nth-last-child(2) {\n    border-bottom-right-radius: 8px;\n    border-bottom-left-radius: 8px;\n    overflow: hidden;\n}\n\n.AQiHs .Q1MPu{\n    display: none;\n}\n\n.AQiHs .Q1MPu svg {\n    width: 56px;\n    height: 56px;\n}\n\n.AQiHs.IgYuZ .Q1MPu{\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n    -ms-flex-pack: center;\n        justify-content: center;\n    height: 100%;\n    width: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n}\n\n.AQiHs .eXJbm {\n    min-height: 128px;\n    padding: 32px;\n}\n\n.AQiHs .eXJbm._00b2V {\n    padding: 0;\n}\n\n.AQiHs.ZpMsH > div{\n    border-bottom-right-radius: 8px;\n    border-bottom-left-radius: 8px;\n    overflow: hidden;\n}\n\n@media (max-width:599px) {\n    .AQiHs .eXJbm{\n        padding: 16px;\n    }\n    .AQiHs .eXJbm._00b2V{\n        padding: 0;\n    }\n}\n\n@media (prefers-color-scheme: dark) {}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"post": "AQiHs",
	"loaded": "qZTlr",
	"error": "IgYuZ",
	"image": "+K-af",
	"noImageIconContainer": "Q1MPu",
	"content": "eXJbm",
	"noPadding": "_00b2V",
	"thereIsNotContent": "ZpMsH"
};
module.exports = ___CSS_LOADER_EXPORT___;
