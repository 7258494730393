import React, {useContext, useEffect, useState} from "react";

import Button from "@material-ui/core/Button";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import AppContext from "../App/context";
import {withMaterialStyles} from "../Template/withMaterial";
import Logo, {SA} from "configurator-studio-arkitekter-components/dist/common/components/Logo";

import materialStyle from "./materialStyle";
import style from "./style.css";

function Home(props) {

    const context = useContext(WappContext);
    const appContext = useContext(AppContext);
    const {wapp} = context;
    const utils = getUtils(context);
    const {menus, routes} = appContext;

    const {subscribe/*, materialStyle*/} = props;

    wapp.styles.use(style);

    const [user, setUser] = useState(utils.getRequestUser());

    function onClick(e, menu) {

        const href = menu.href;

        wapp.client.history.push({
            search:"",
            hash:"",
            ...wapp.client.history.parsePath(href)
        });

        e.preventDefault();

    }

    function onUserChange(user){
        setUser((user?._id) ? user : null);
    }

    useEffect(function didMount(){
        const unsub = subscribe.userChange(onUserChange);
        return function willUnmount() {
            unsub();
        }
    }, [subscribe, user]);

    return (
        <div className={style.home}>
            <div className={style.centerContainer}>
                <div className={style.logoContainer}>
                    <div className={style.logo}>
                        <Logo />
                    </div>
                    <div className={style.SA}>
                        <SA />
                    </div>
                </div>
                <div className={style.buttons}>
                    {(user?._id) ?
                        <Button
                            onClick={(e) => onClick(e, {href:routes.userRoute + "/" + user._id})}
                            children={menus.dashboardMenu}
                            variant={"contained"}
                            color={"secondary"}
                        /> :
                        <Button
                            onClick={(e) => onClick(e, {href:routes.accountRoute + "/login"})}
                            children={menus.loginMenu}
                            variant={"contained"}
                            color={"secondary"}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

const WappComponent = withWapp(Home);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
