import getPostMenu from "../Post/menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import capitalize from "../../utils/capitalize";

export function getTopMenu(props) {

    const {appContext/*, statusManager*/} = props;
    const {menus, routes/*, userStatusManager*/} = appContext;

    return [
        {
            name: menus.accountSettingsMenu,
            href: function () {
                return routes.accountRoute;
            },
            role: function (p) {
                const isAuthor = ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id));
                if (isAuthor) {
                    return !!(p?.post?._id);
                }
                return false;
            },
            Icon: SettingsIcon,
            disableParentRoute: true,
            onlyIcon: true,
            featured: true
        }
    ]

}

function getMenu(props = {}) {

    // eslint-disable-next-line no-unused-vars
    const {appContext, statusManager, addMenuItems, ...rest} = props;
    const {menus, /*userStatusManager*/} = appContext;

    const name = rest.name;
    const N = capitalize(name);

    const postMenu = getPostMenu(props);
    const filtered = postMenu.map(function (m) {
        if (m.name === menus["delete"+N+"Menu"] || m.name === menus["edit"+N+"Menu"]){
            const role = m.role;
            m.role = function (p) {
                const isAdmin = p.user && p.user._status_isFeatured;
                if (!isAdmin) {
                    return false;
                }
                return role(p)
            };
            m.featured = false;
        }
        m.order = typeof m.order === "number" ? m.order : 200;
        return m;
    });

    const addMenuItemsArray =  (addMenuItems) ? addMenuItems({...rest, appContext, statusManager}) : [];

    return [
        {
            name: function (p) {
                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id));
                return (isAuthor) ? menus.dashboardMenu : menus.userProfileMenu;
            },
            href: function (p) {
                return (p.post?._id) ? "/"+p.post._id : "/"
            },
            role: function () {
                return true;
            },
            Icon: AccountCircleIcon,
        },
        ...(addMenuItemsArray.length) ? [{divider: true}] : [],
        ...addMenuItemsArray,
        ...(filtered.length) ? [{divider: true, role: (p)=>p.user && p.user._status_isFeatured, order:200}] : [],
        ...filtered
    ];
}

export default getMenu;
