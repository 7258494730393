"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var titles = {
  homeTitle: "Home",
  modelsTitle: "Models",
  collectionsTitle: "Collections",
  summaryTitle: "Summary"
};
var _default = titles;
exports["default"] = _default;