"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var labels = {
  prevLabel: "Prev",
  nextLabel: "Next",
  summaryLabel: "Summary",
  modelsLabel: "Models",
  collectionsLabel: "Collections",
  copyUrlToClipboardLabel: "Copy url to clipboard",
  printLabel: "Print",
  selectLabel: "Select",
  selectedLabel: "Selected",
  languages: {
    lang_1: "Magyar",
    lang_2: "English"
  }
};
var _default = labels;
exports["default"] = _default;