"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = makeMaterialStyle;

function makeMaterialStyle(theme) {
  return {
    cardExpandIcon: {
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    }
  };
}