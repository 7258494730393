import defaultPostTypeConfig from "../post";

export default function getStatusManager(p = {}) {
    return defaultPostTypeConfig.getStatusManager({
        config: {
            requiredDataForStatus: {
                name: {
                    first: { type: String },
                },
                email: { type: String },
                emailConfirmed: { type: Boolean },
                ...(p.config?.requiredDataForStatus) ? p.config.requiredDataForStatus : {}
            },
            ...(p.config) ? p.config : {}
        },
    })
}
