import React from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Avatar from "../Avatar/me";

function getMenu(props = {}) {

    const {appContext} = props;
    const {menus, routes} = appContext;

    async function onClickBefore() {
        if (appContext.template?.actions){
            appContext.template.actions.storeDrawerScrollTop();
        }
        if (appContext.template?.actions){
            await appContext.template.actions.drawerCloseNarrow();
        }
    }

    return [
        {
            name: menus.loginMenu,
            href: routes.accountRoute + "/login",
            role: function (p) {
                return !(p?.user?._id);
            },
            Icon: AccountCircleIcon,
            onlyIcon: true,
            featured: true,
            onClickBefore
        },
        {
            name: menus.dashboardMenu,
            href: function (p) {
                return (p?.user?._id) ? routes.userRoute + "/" + p.user._id : routes.userRoute;
            },
            role: function (p) {
                return p?.user?._id;
            },
            Icon: function () {
                return (
                    <Avatar />
                )
            },
            onlyIcon: true,
            featured: true,
            onClickBefore
        },
    ];

}

export default getMenu;
