import React, {useContext, useRef} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
//import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import {withMaterialStyles} from "../../../components/Template/withMaterial";

import materialStyle from "./materialStyle";
import style from "./style.css";
import PostContext from "../../../components/Post/context";

function Content() {

    const container = useRef();
    const postContext = useContext(PostContext);
    const {post} = postContext;

    const context = useContext(WappContext);
    //const utils = getUtils(context);
    //const {materialStyle} = props;

    const {wapp} = context;

    wapp.styles.use(style);

    return (
        <div className={style.post} ref={container}>
            <div className={style.content} dangerouslySetInnerHTML={{__html: post?.content}} />
        </div>
    )
}

const WappComponent = withWapp(Content);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
