import {runPostTypesConfigSync} from "../../postTypes";

const labels = {
    notFoundButtonBack: "Previous page",
    notFoundButtonHome: "Home page",
    notFoundButtonLogin: "Login",
    notFoundButtonSignup: "Signup",
    acceptCookiesLabel: "Accept",
    acceptCookiesClose: "Close",
    selectAnOperationLabel: "Select an operation",
    searchLabel: "Search",
    ...runPostTypesConfigSync({action:"getConstants", rKey:"labels"}).reduce((a, v) => {return {...a, ...v}}, {})
};

export default labels;
