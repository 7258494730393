import React, {useContext, useState, useEffect, forwardRef, useRef, useMemo} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";
//import getUtils from "wapplr-react/dist/common/Wapp/getUtils";

import clsx from "clsx";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Snackbar from "@material-ui/core/Snackbar";

import {storage as defaultLocalStorage} from "configurator-studio-arkitekter-components/dist/common/utils/localStorage";
import {withMaterialStyles} from "../Template/withMaterial";

import style from "./style.css";
import materialStyle from "./materialStyle";
import SchemaFormContext from "./context";

import defaultValidationFunction from "./validations.js";
import createStateManager from "./stateManager";
import Dialog from "../Dialog";
import AppContext from "../App/context";

function ErrorMark(props) {

    const {propertyTreeString} = props;

    const schemaFormContext = useContext(SchemaFormContext);
    const {style, stateManager} = schemaFormContext;

    const state = stateManager.getState();

    const foundInvalidChildren = (state.invalid) ? Object.keys(state.invalid).filter((key)=>key.startsWith(propertyTreeString) && state.invalid[key]) : []

    const [isValid, _setIsValid] = useState(!foundInvalidChildren.length);

    const setIsValid = async(newValue) => {
        if (isValid !== newValue){
            _setIsValid(newValue);
        }
    }

    useEffect(() => stateManager.subscribe(async (state, {type, payload}) => {
        if (type === "SET_INVALID"){
            const changingItemPropertyTreeString = payload.name;
            if (changingItemPropertyTreeString.startsWith(propertyTreeString)){
                const foundInvalidChildren = (state.invalid) ? Object.keys(state.invalid).filter((key)=>key.startsWith(propertyTreeString) && state.invalid[key]) : [];
                await setIsValid((!foundInvalidChildren.length))
            }
        }
    }), [isValid, stateManager])

    useEffect(()=>{
        const state = stateManager.getState();
        const foundInvalidChildren = (state.invalid) ? Object.keys(state.invalid).filter((key)=>key.startsWith(propertyTreeString) && state.invalid[key]) : [];
        const newIsValid = !foundInvalidChildren.length;
        if (newIsValid !== isValid){
            setIsValid(newIsValid);
        }
    })

    return (
        <>
            {(!isValid) ? <div className={style.objectFieldErrorMark}/> : null}
        </>
    )
}

function Label(props) {

    const schemaFormContext = useContext(SchemaFormContext);
    const {schema} = props;

    const [label, _setLabel] = useState(props.label || "");

    const setLabel = async(newValue) => {
        if (label !== newValue){
            _setLabel(newValue);
        }
    }

    useEffect(() => {
        if (schema.useEffectForLabel){
            return schema.useEffectForLabel({setLabel, schemaFormContext, props});
        }
    }, [label])

    return (
        <>
            {label}
        </>
    )
}

function ObjectField(props) {

    const schemaFormContext = useContext(SchemaFormContext);
    const {style, storage} = schemaFormContext;
    const {value, schema, label, propertyTreeString, disableRender, enableDelete } = props;

    const isMounted = useRef(false);
    const storageOpen = (storage) ? !!(storage()[propertyTreeString + "_mo"]) : false;
    const [open, setOpen] = useState((isMounted.current) ? storageOpen : false);

    const handleExpandClick = async () => {
        storage({[propertyTreeString+"_mo"] : !open})
        await setOpen(!open);
    };

    const onDelete = async () => {
        await props.onDelete();
    }

    useEffect(()=> {
        isMounted.current = true;
        const storageOpen = (storage) ? !!(storage()[propertyTreeString + "_mo"]) : false;
        if (storageOpen !== open){
            setOpen(!open)
        }
        return ()=> isMounted.current = false;
    }, [])

    if (disableRender){
        return (
            <>
                {
                    Object.keys(schema.properties).map((key) =>
                        <FieldTemplate
                            key={key}
                            value={value[key]}
                            schema={schema.properties[key]}
                            propertyKey={key}
                            parentValue={value}
                            parentPropertyTreeString={propertyTreeString}
                            disableRender={true}
                        />
                    )}
            </>
        )
    }

    return (
        <div className={clsx(
            style.objectField,
            {
                [style.objectOpened] : open,
                [style.objectClosed] : !open,
            }
        )}>
            <div className={style.objectHeader}>
                <ErrorMark propertyTreeString={propertyTreeString} />
                <div className={style.objectTitle}><Label label={label} propertyTreeString={propertyTreeString} schema={schema} value={value}/></div>
                <div className={style.objectHeaderButtons}>
                    <IconButton
                        onClick={()=> handleExpandClick()}
                        aria-label={"open"}
                    >
                        {(open) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    {(enableDelete) ?
                        <IconButton
                            onClick={onDelete}
                            aria-label={"delete"}
                        >
                            <DeleteIcon />
                        </IconButton>
                        : null
                    }
                </div>
            </div>
            <div className={style.objectContent}>
                {
                    Object.keys(schema.properties).map((key) =>
                        <FieldTemplate
                            key={key}
                            value={value[key]}
                            schema={schema.properties[key]}
                            propertyKey={key}
                            parentValue={value}
                            parentPropertyTreeString={propertyTreeString}
                            disableRender={!(open)}
                        />
                    )}
            </div>
        </div>
    );
}

function ArrayField(props) {

    const appContext = useContext(AppContext);
    const schemaFormContext = useContext(SchemaFormContext);
    const {style, storage, dialog} = schemaFormContext;
    const {schema, ...rest} = props;
    const {value, label, propertyTreeString, disableRender, enableDelete } = rest;

    const isMounted = useRef(false);
    const storageOpen = (storage) ? !!(storage()[propertyTreeString + "_mo"]) : false;
    const [open, setOpen] = useState((isMounted.current) ? storageOpen : false);

    const handleExpandClick = async () => {
        storage({[propertyTreeString+"_mo"] : !open})
        await setOpen(!open);
    };

    const onAdd = async() => {
        if (schema.add){
            await schema.add({schemaFormContext, schema, props:rest, value});
        }
    }

    const onDelete = async() => {
        await props.onDelete();
    }

    useEffect(()=> {
        isMounted.current = true;
        const storageOpen = (storage) ? !!(storage()[propertyTreeString + "_mo"]) : false;
        if (storageOpen !== open){
            setOpen(!open)
        }
        return ()=> isMounted.current = false;
    }, [])

    if (disableRender){
        return (
            <>
                {value && value.map((item, key) =>
                    <FieldTemplate
                        key={key}
                        value={item}
                        schema={schema.items}
                        propertyKey={key}
                        parentValue={value}
                        parentPropertyTreeString={propertyTreeString}
                        disableRender={true}
                    />
                )}
            </>
        )
    }

    return (
        <div className={clsx(
            style.objectField,
            {
                [style.objectOpened] : open,
                [style.objectClosed] : !open,
            }
        )}>
            <div className={style.objectHeader}>
                <ErrorMark propertyTreeString={propertyTreeString} />
                <div className={style.objectTitle}><Label label={label} propertyTreeString={propertyTreeString} schema={schema} value={value}/></div>
                <div className={style.objectHeaderButtons}>
                    <IconButton
                        onClick={()=> handleExpandClick()}
                        aria-expanded={open}
                        aria-label={"open"}
                    >
                        {(open) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    {(schema.add) ?
                        <IconButton
                            onClick={onAdd}
                            aria-label={"add"}
                        >
                            <AddIcon />
                        </IconButton>
                        : null
                    }
                    {(enableDelete) ?
                        <IconButton
                            onClick={onDelete}
                            aria-label={"delete"}
                        >
                            <DeleteIcon />
                        </IconButton>
                        : null
                    }
                </div>
            </div>
            <div className={style.objectContent}>
                {value && value.map((item, key) =>
                    <FieldTemplate
                        key={key}
                        value={item}
                        schema={schema.items}
                        propertyKey={key}
                        parentValue={value}
                        parentPropertyTreeString={propertyTreeString}
                        disableRender={!(open)}
                        componentProps={{
                            onDelete: async() => {

                                await new Promise((resolve)=>{

                                    dialog.actions.open({
                                        dialogTitle: appContext.titles.dialogDeleteSchemaFormItem || "Delete item",
                                        dialogContent: appContext.messages.deleteSchemaFormItemQuestion || "Are you sure want to delete this item?",
                                        cancelText: appContext.labels.cancelText || "Cancel",
                                        submitText: appContext.labels.deleteText || "Delete",
                                        successMessage: appContext.messages.deleteSchemaFormItemSuccess || "This item has been deleted",
                                        onSubmit: async ()=> {

                                            value.splice(key, 1);
                                            await props.setValue([], true);
                                            await props.setValue(value, true);

                                            if (schema.items.onDelete){
                                                await schema.items.onDelete({schemaFormContext, schema: schema.items})
                                            }

                                            await schemaFormContext.update();

                                            resolve();

                                            return true;
                                        },
                                    })

                                })

                            }
                        }}
                    />
                )}
            </div>
        </div>
    );
}

const components = {
    Button: {
        props: {
            type: "submit",
            variant: "contained",
            color: "secondary",
            children: "Submit"
        },
        Component: Button
    },
    TextField: {
        props: {
            type: "text",
            label: "",
            value: "",
            error: false,
            helperText: "",
            variant: "outlined",
            autoComplete: "on",
            disabled: false,
            multiline: false,
            minRows: null,
            maxRows: null
        },
        Component: (props) => {
            const {errorMessage, isValid, schema, propertyTreeString, setValue, onDelete, enableDelete, ...rest} = props;
            const errorProps = (errorMessage && !isValid) ? {helperText: errorMessage, error: true} : {helperText: ""};

            let {value} = rest;

            if (typeof value === "undefined"){
                value = getDefaultEmptyValue({schema});
            }

            return (
                <div className={style.textFieldContainer}>
                    <div className={style.textFieldItem}>
                        <TextField {...rest}
                                   {...errorProps}
                                   value={value} />
                    </div>
                    {(enableDelete) ?
                        <div className={style.textFieldButtons}>
                            <IconButton
                                onClick={onDelete}
                                aria-label={"delete"}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                        : null
                    }
                </div>
            )
        }
    },
    NumberField: {
        props: {
            type: "text",
            label: "",
            value: "",
            error: false,
            helperText: "",
            variant: "outlined",
            autoComplete: "on",
            disabled: false,
            multiline: false,
            minRows: null,
            maxRows: null
        },
        Component: (props) => {
            const {errorMessage, isValid, schema, propertyTreeString, setValue, onDelete, enableDelete, onChange, ...rest} = props;
            const errorProps = (errorMessage && !isValid) ? {helperText: errorMessage, error: true} : {helperText: ""};

            let {value} = rest;

            if (typeof value === "undefined"){
                value = getDefaultEmptyValue({schema});
            }

            if (typeof value === "number" && value === 0){
                value = "0";
            }

            return (
                <div className={style.textFieldContainer}>
                    <div className={style.textFieldItem}>
                        <TextField {...rest}
                                   {...errorProps}
                                   onChange={async (e)=> {
                                       let value = e.target?.value || "";
                                       value = value.replace(/ /g, "");
                                       if (value && typeof value === "string" && !isNaN(Number(value))){
                                           const lastIsAPoint = value && value.toString().slice(-1) === ".";
                                           value = Number(value);
                                           if (lastIsAPoint){
                                               value = value.toString() + "."
                                           }
                                       }
                                       await onChange(e, value);
                                   }}
                                   value={value} />
                    </div>
                    {(enableDelete) ?
                        <div className={style.textFieldButtons}>
                            <IconButton
                                onClick={onDelete}
                                aria-label={"delete"}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                        : null
                    }
                </div>
            )
        }
    },
    Checkbox: {
        props: {
            label: "",
            value: "",
        },
        Component: (props) => {

            const {errorMessage, isValid, schema, propertyTreeString, setValue, onDelete, ...rest} = props;
            const {label, onChange, className, ...otherProps} = rest;

            let {value} = rest;

            if (typeof value === "undefined"){
                value = getDefaultEmptyValue({schema});
            }

            return (
                <FormControlLabel
                    className={className}
                    label={label}
                    control={
                        <Checkbox
                            checked={value}
                            onChange={async (e)=> {
                                await onChange(e, e.target.checked);
                            }}
                            {...otherProps}
                        />
                    }
                />
            )
        }
    },
    Select: {
        props: {
            label: "",
            value: "",
            multiple: false,
            selectOptions: null
        },
        Component: (props) => {

            const schemaFormContext = useContext(SchemaFormContext);

            const {errorMessage, isValid, schema, propertyTreeString, setValue, onDelete, enableDelete, ...rest} = props;
            const errorProps = (errorMessage && !isValid) ? {helperText: errorMessage, error: true} : {helperText: ""};

            const {value, label, onChange, selectOptions = {}, className, ...otherProps} = rest;

            const so = (typeof selectOptions === "function") ? selectOptions({schemaFormContext, props}) : selectOptions;

            return (
                <FormControl {...errorProps.error ? {error: true} : {}} className={className}>
                    <InputLabel id={propertyTreeString + "select-label"}>{label}</InputLabel>
                    <Select
                        labelId={propertyTreeString + "select-label"}
                        id={propertyTreeString + "select"}
                        value={value}
                        label={label}
                        onChange={(e)=>{onChange(e)}}
                        {...otherProps}
                    >
                        {(so && so.length) ? so.map(({label, value}, i)=><MenuItem key={i} value={value}>{label}</MenuItem>) : null}
                    </Select>
                    {(errorProps.error) ? <FormHelperText>{errorProps.helperText}</FormHelperText> : null}
                </FormControl>
            )
        }
    },
    Object: {
        props: {
            label: "",
            schema: {},
            value: {},
            disableRender: false
        },
        Component: ObjectField
    },
    Array: {
        props: {
            label: "",
            schema: [],
            value: [],
            disableRender: false
        },
        Component: ArrayField
    }
};

function FieldTemplate (props) {

    const schemaFormContext = useContext(SchemaFormContext);
    const {style, validationFunction, stateManager} = schemaFormContext;

    const {schema, propertyKey, parentValue, parentPropertyTreeString, disableRender} = props;
    const propertyTreeString = (parentPropertyTreeString) ? parentPropertyTreeString + "." + propertyKey : propertyKey;

    let newMissingValue;
    if (!props.value){
        const componentName = getComponentName({schema});
        const Component = components[componentName]?.Component || TextField;
        if (Component === ObjectField || Component === ArrayField){
            newMissingValue = getDefaultEmptyValue({schema});
            parentValue[propertyKey] = newMissingValue;
        }
    }

    const [value, _setValue] = useState(typeof props.value === "undefined" ? newMissingValue : props.value);

    const [render, setRender] = useState(1);

    const sendPropsForSchema = {schema, schemaFormContext, props:{propertyKey, propertyTreeString, parentValue, value, style}};

    const initialIsValidObject = validationFunction(sendPropsForSchema);
    const initialIsValid = initialIsValidObject.isValid;
    const initialErrors = initialIsValidObject.errors;
    const initialErrorMessage = (initialErrors?.length) ? initialErrors[0] : "";

    const [isValid, _setIsValid] = useState(initialIsValid);
    const [errorMessage, _setErrorMessage] = useState(initialErrorMessage);

    const propsAndComponent = generatePropsAndSelectComponent(sendPropsForSchema);
    const componentProps = {...(props.componentProps) ? props.componentProps : {}, ...propsAndComponent.props};
    const Component = propsAndComponent.Component;
    const {...rest} = componentProps;

    const setInvalidState = (newValue) => {
        if (Component !== ObjectField && Component !== ArrayField) {
            const state = stateManager.getState();
            if (!state.invalid || state.invalid && state.invalid[propertyTreeString] !== newValue) {
                stateManager.dispatch(stateManager.runAction("invalid", {name: propertyTreeString, value: newValue}));
            }
        }
    }

    const setErrorMessageState = (newValue) => {
        if (Component !== ObjectField && Component !== ArrayField) {
            const state = stateManager.getState();
            if (!state.errorMessage || state.errorMessage && state.errorMessage[propertyTreeString] !== newValue) {
                stateManager.dispatch(stateManager.runAction("errorMessage", {name: propertyTreeString, value: newValue}));
            }
        }
    }

    const setIsValid = async(newValue) => {
        if (isValid !== newValue) {
            setInvalidState(!newValue);
            await _setIsValid(newValue);
        }
    }

    const setErrorMessage = async(newValue) => {
        if (errorMessage !== newValue) {
            setErrorMessageState(newValue);
            await _setErrorMessage(newValue);
        }
    }

    const setValue = async (newValue, force) => {
        await _setValue(newValue);
        parentValue[propertyKey] = newValue;
        if (force){
            setRender(render+1)
        }
    }

    const onChange = async (e, v) => {
        const newValue = (typeof v !== "undefined") ? v : e.target?.value;
        if (typeof newValue !== "undefined" && value !== newValue) {
            await _setValue(newValue);
            parentValue[propertyKey] = newValue;
            if (schema.onChange){
                const {schema, schemaFormContext, props} = sendPropsForSchema;
                schema.onChange({schema, schemaFormContext, props: {...props, value:newValue}})
            }
        }
    }

    useEffect(()=> {

        const sendPropsForSchema = {schema, schemaFormContext, props:{propertyKey, propertyTreeString, parentValue, value, style}};

        const newIsValidObject = validationFunction(sendPropsForSchema);
        const newIsValid = newIsValidObject.isValid;
        const newErrors = newIsValidObject.errors;
        const newErrorMessage = (newErrors?.length) ? newErrors[0] : "";

        if (newIsValid !== isValid) {

            async function wait() {
                await setErrorMessage(newErrorMessage);
                await setIsValid(newIsValid);
            }

            wait();

        }

    }, [value, schemaFormContext, schema, stateManager, parentValue, value])

    useEffect(()=> {
        if (!isValid) {
            setErrorMessageState(errorMessage);
            setInvalidState(!isValid);
        }
    }, [stateManager])

    const enableDelete = (schema.enableDelete === true || typeof schema.enableDelete === "function" && schema.enableDelete(sendPropsForSchema));

    return (
        <>
            {(disableRender) ?
                (Component === ObjectField || Component === ArrayField) ?
                    <Component {...rest}
                               value={value}
                               onChange={onChange}
                               setValue={setValue}
                               errorMessage={errorMessage}
                               isValid={isValid}
                               disableRender={true}
                               enableDelete={enableDelete}
                    />
                    : null
                :
                <div className={style.fieldContainer}>
                    <div className={style.fieldItem}>
                        <Component {...rest}
                                   value={value}
                                   onChange={onChange}
                                   setValue={setValue}
                                   errorMessage={errorMessage}
                                   isValid={isValid}
                                   enableDelete={enableDelete}
                        />
                    </div>
                </div>
            }
        </>
    );
}

function getDefaultEmptyValue({schema}) {
    let defaultEmptyValue = "";
    if (schema.schemaType){
        switch(schema.schemaType) {
            case "Object":
                defaultEmptyValue = {};
                break;
            case "Array":
                defaultEmptyValue = [];
                break;
            case "String":
                defaultEmptyValue = "";
                break;
            case "Number":
                defaultEmptyValue = "";
                break;
            case "Boolean":
                defaultEmptyValue = false;
                break;
            default:
                defaultEmptyValue = "";
        }
    }
    return defaultEmptyValue;
}

function getComponentName({schema}) {
    if (schema.componentName){
        return schema.componentName;
    }
    let componentName = "TextField";
    if (schema.schemaType){
        switch(schema.schemaType) {
            case "Object":
                componentName = "Object";
                break;
            case "Array":
                componentName = "Array";
                break;
            case "String":
                componentName = "TextField";
                break;
            case "Number":
                componentName = "NumberField";
                break;
            case "Boolean":
                componentName = "Checkbox";
                break;
            default:
                componentName = "TextField";
        }
    }
    return componentName;
}

function generatePropsAndSelectComponent(p) {

    const {propertyKey, propertyTreeString = "", value, style} = p.props;
    const {schemaFormContext, schema} = p;

    const componentName = getComponentName({schema});

    const Component = components[componentName]?.Component || TextField;
    const defaultProps = {...components[componentName]?.props || {}};

    const props = Object.keys(defaultProps).reduce(function (a, key) {
        a[key] = (typeof schema[key] !== "undefined") ? schema[key] : defaultProps[key];
        return a;
    }, {});

    if (props.label === ""){
        props.label = (propertyKey.slice) ? propertyKey.slice(0,1).toUpperCase() + propertyKey.slice(1) : propertyKey.toString();
        props.label = (props.label?.replace) ? props.label?.replace(/_/g, " ") : props.label;
        if (typeof schema.generateLabel === "function"){
            props.label = schema.generateLabel({schema, schemaFormContext, props:{...p.props, value}});
        }
    }

    if (schema.required && props.label && props.label.slice(-2) !== " *") {
        props.label = props.label + " *";
    }

    props.schema = schema;
    props.key = propertyTreeString;
    props.propertyTreeString = propertyTreeString;
    props.value = value;
    props.className = (props.className) ? props.className + " " + style[componentName] : style[componentName];

    return {props, Component};

}

const SchemaForm = forwardRef((props, ref) =>{

    //const appContext = useContext(AppContext);
    const context = useContext(WappContext);
    //const utils = getUtils(context);

    const {wapp, /*res*/} = context;

    wapp.styles.use(style);

    const [snackMessage, setSnackMessage] = useState("");

    const {
        /*subscribe, materialStyle,*/
        data,
        storageName = "SchemaEditor",
        FormComponent = (props)=> {
            const {children, ...rest} = props;
            return (
                <form {...rest}>{props.children}</form>
            )
        },
        validationFunction = defaultValidationFunction,
    } = props;

    const storage = (data) => {
        return defaultLocalStorage(data, storageName);
    }

    const handleCloseSnackbar = (/*e, reason*/) => {
        if (snackMessage) {
            setSnackMessage("");
        }
    }

    const onSubmit = async (e) => {

        const {successMessage} = props;

        e.preventDefault();

        const {
            onSubmit = async function onSubmit(e, {data, error}) {
                return new Promise(async function (resolve) {
                    return resolve({data, error});
                })
            }
        } = props;

        const state = stateManager.getState();
        const errors = (state.errorMessage) ? Object.keys(state.errorMessage).filter((key) => state.errorMessage[key]).reduce((o, key)=>{o[key] = state.errorMessage[key]; return o;}, {}) : [];

        const error = (Object.keys(errors).length) ? {errors, message: errors[Object.keys(errors)[0]] } : null;

        const response = await onSubmit(e, {data, error});

        if (response && response.error){

            const message = response.error.message;

            if (message && snackMessage !== message){
                setSnackMessage(message);
            }

        } else if (response){
            if (successMessage){
                setSnackMessage(successMessage);
            }
        }

        return response;

    }

    useEffect(() => {
        ref({
            onSubmit,
            isValid: () => {
                const state = stateManager.getState();
                const invalids = (state.invalid) ? Object.keys(state.invalid).filter((key) => state.invalid[key]) : [];
                return (!invalids.length);
            }
        })
    })

    const [schema, _setSchema] = useState(props.schema);
    const [render, _setRender] = useState(1);

    const update = async () => {
        await _setRender(render+1)
    }

    const setSchema = async (newSchema) => {
        if (newSchema !== schema){
            await _setSchema(newSchema);
        }
    }

    const dialog = {
        actions: {}
    };

    const dialogEffect = function ({actions}) {
        dialog.actions = actions;
    };

    const stateManager = useMemo(()=>createStateManager().createStore(), [schema, render]);

    return (
        <SchemaFormContext.Provider value={{rootData: data, style, storage, validationFunction, stateManager, setSchema, props, dialog, update}}>
            <FormComponent
                className={style.schemaForm}
                autoComplete={"off"}
                noValidate
                onSubmit={onSubmit}
            >
                <FieldTemplate
                    key={"root"}
                    propertyKey={"root"}
                    schema={schema}
                    parentValue={{root: data}}
                    value={data}
                    parentPropertyTreeString={""}
                />
            </FormComponent>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={!!(snackMessage)}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackMessage}
            />
            <Dialog effect={dialogEffect} />
        </SchemaFormContext.Provider>
    )
})

const WappComponent = withWapp(SchemaForm);

export default withMaterialStyles(materialStyle, WappComponent);
