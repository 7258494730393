import React, {useContext, useRef, useLayoutEffect, useState, useMemo} from "react";

import {WappContext, withWapp} from "wapplr-react/dist/common/Wapp";

import HideImageOutlinedIcon from "@material-ui/icons/HideImageOutlined";

import {withMaterialStyles} from "../../../components/Template/withMaterial";

import PostContext from "../../../components/Post/context";
import AppContext from "../../../components/App/context";

import VideoPlayer from "../../../components/VideoPlayer";

import materialStyle from "./materialStyle";
import style from "./style.css";
import clsx from "clsx";

function Content() {

    const container = useRef();
    const image = useRef();

    const postContext = useContext(PostContext);
    const appContext = useContext(AppContext);
    const {post} = postContext;

    const context = useContext(WappContext);

    const {wapp, req} = context;

    //const {userStatusManager} = appContext;
    const user = req.wappRequest.user;

    wapp.styles.use(style);

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const onLoad = useMemo(()=> function () {
        if (!loaded){
            setLoaded(true);
        }
    }, [loaded]);

    const onError = useMemo(()=> function () {
        if (!error){
            setError(true);
        }
    }, [error]);

    if (wapp.target !== "node") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useLayoutEffect(function () {
            if (!error && !loaded) {
                const imageUrl = (post.fileType === "image") ? post.fileUrl : post.thumb;
                const isImage = (post.fileType === "image");
                if (isImage) {
                    const testImage = new Image();
                    testImage.onerror = onError;
                    testImage.onload = onLoad;
                    testImage.src = imageUrl;
                }
            }
        }, [post, onLoad, onError, image, error, loaded]);
    }

    const isAdmin = user && user._status_isFeatured;
    const alt = post.title || post.fileType || "file";
    const imageUrl = (post.fileType === "image") ? post.fileUrl : post.thumb;
    const videoUrl = (post.fileType === "video") ? post.fileUrl : null;
    const isVideo = (post.fileType === "video");
    const isImage = (post.fileType === "image");

    return (
        <div
            className={clsx(
                style.post,
                {[style.loaded]: loaded},
                {[style.error]: error},
                {[style.thereIsNotContent]: !post.content}
            )}
            ref={container}
        >
            {(isImage && imageUrl) ?
                (isAdmin) ?
                    <a rel={"noreferrer"} href={imageUrl} target={"_blank"}>
                        <img alt={alt}
                             src={imageUrl}
                             className={style.image}
                             ref={image}
                             onLoad={onLoad}
                             onError={onError}
                        />
                    </a> :
                    <img
                        alt={alt}
                        src={imageUrl}
                        className={style.image}
                        ref={image}
                        onLoad={onLoad}
                        onError={onError}
                    />
                : null
            }
            {(isVideo && videoUrl) ?
                <VideoPlayer
                    src={videoUrl}
                    cover={imageUrl}
                    id={"video-file"}
                    appContext={appContext}
                    layerInteraction={"pause"}
                    autoplay={false}
                    title={post.title}

                    enableBlackLayer={true}
                    bigPlayButton={true}
                    simpleControls={true}
                    relative={true}
                    enableFullScreen={"enableFullScreenCinemaModMin800"}
                    theme={"darkTheme"}
                    backgroundColor={"transparentBackground"}
                    bigPlayButtonTheme={"whitePlayButtonTheme"}

                />
                : null
            }
            {
                (post.content)?
                <div className={style.content}>
                    {post?.content}
                </div> :
                null
            }
            <div className={style.noImageIconContainer}>
                <HideImageOutlinedIcon />
            </div>
        </div>
    )
}

const WappComponent = withWapp(Content);

const StyledComponent = withMaterialStyles(materialStyle, WappComponent);

export default StyledComponent;
