import capitalize from "../../utils/capitalize";

export default function getConstants(p = {}) {

    const {name = "post"} = p;

    const n = name;
    const N = capitalize(n);
    const ns = (name.endsWith("y")) ? name.slice(0,-1)+"ies" : name+"s";
    const Ns = capitalize(ns);

    return {
        labels: {
            ["new"+N+"SubmitLabel"]: "Save",
            ["restore"+N+"SubmitLabel"]: "Restore",
            ["save"+N+"SubmitLabel"]: "Save",
            ["cancel"+N+"Text"]: "Cancel",
            ["delete"+N+"Text"]: "Delete",
            ["delete"+Ns+"Text"]: "Delete "+ns,
            ["ban"+N+"Text"]: "Ban",
            ["approve"+N+"Text"]: "Approve",
            ["protection"+N+"Text"]: "Protect",
            ["removeProtection"+N+"Text"]: "Remove protection",
            ["select"+N+"Text"]: "Select",
            ["add"+N+"Text"]: "Add",
            ["date"+N+"Format"]: ({dateText = ""})=>{
                if (!dateText) {
                    return dateText;
                }
                try {
                    return new Date(dateText).toLocaleString("en-US");
                } catch (e){}
                return dateText;
            },
            [ns+"SortRelevance"]: "Relevance",
            [n+"CoverLabel"]: "Cover",
            [n+"AuthorLabel"]: "Author",
            [n+"TitleLabel"]: "Title",
        },
        menus: {
            ["user"+Ns+"Menu"]: Ns,
            ["my"+Ns+"Menu"]: "My "+ns,
            ["new"+N+"Menu"]: "Create a "+n,
            [ns+"Menu"]: Ns,
            ["deleted"+Ns+"Menu"]: "Deleted "+ns,
            ["banned"+Ns+"Menu"]: "Banned "+ns,
            ["protection"+Ns+"Menu"]: "Protected "+ns,
            ["missingData"+Ns+"Menu"]: "Missing data",
            ["lowAuthorStatus"+Ns+"Menu"]: "Author low status",

            ["edit"+N+"Menu"]: "Edit",
            ["delete"+N+"Menu"]: "Delete",
            ["ban"+N+"Menu"]: "Ban",
            ["approve"+N+"Menu"]: "Approve",
            ["protection"+N+"Menu"]: "Protect",
            ["removeProtection"+N+"Menu"]: "Remove protection",
        },
        messages: {
            ["new"+N+"SuccessMessage"]: "The new "+n+" has beed saved",
            ["save"+N+"SuccessMessage"]: "The "+n+" has been saved",
            ["delete"+N+"Success"]: "This "+n+" has been deleted",
            ["delete"+Ns+"Success"]: "These "+ns+" have been deleted",
            ["delete"+N+"Question"]: "Are you sure want to delete this "+n+"?",
            ["delete"+Ns+"Question"]: "Are you sure want to delete these "+ns+"?",
            ["ban"+N+"Success"]: "This "+n+" has been banned",
            ["ban"+N+"Question"]: "Are you sure want to ban this "+n+"?",
            ["approve"+N+"Success"]: "This "+n+" has been approved",
            ["approve"+N+"Question"]: "Are you sure want to approve this "+n+"?",
            ["protection"+N+"Success"]: "This "+n+" has been protected",
            ["protection"+N+"Question"]: "Are you sure want to protect this "+n+"?",
            ["removeProtection"+N+"Success"]: "The protection of this "+n+" has been removed",
            ["removeProtection"+N+"Question"]: "Are you sure want to remove protection of this "+n+"?",
            ["thereAreNo"+Ns]: "There are no "+ns
        },
        routes: {
            ["user"+Ns+"Route"]: "/"+ns,
            [n+"Route"]: "/"+n,
            [ns+"Route"]: "/"+ns,
        },
        titles: {
            [n+"Title"]: N,
            [ns+"Title"]: Ns,
            ["new"+N+"Title"]: "New "+n,
            ["edit"+N+"Title"]: "Edit "+n,
            ["user"+Ns+"Title"]: Ns,
            ["userDeleted"+Ns+"Title"]: "Deleted "+ns,
            ["my"+Ns+"Title"]: "My "+ns,
            ["myDeleted"+Ns+"Title"]: "My deleted "+ns,

            ["dialogDelete"+N+"Title"]: "Delete "+n,
            ["dialogBan"+N+"Title"]: "Ban "+n,
            ["dialogApprove"+N+"Title"]: "Approve "+n,
            ["dialogProtection"+N+"Title"]: N+" protection",
            ["dialogRemoveProtection"+N+"Title"]: "Remove protection",

            ["dialogDelete"+Ns+"Title"]: "Delete "+ns,

            ["statusBanned"+N+"Title"]: "Banned",
            ["statusDeleted"+N+"Title"]: "Deleted",
            ["statusAuthorDeleted"+N+"Title"]: "Author deleted",
            ["statusMissingData"+N+"Title"]: "Missing data",
            ["statusApproved"+N+"Title"]: "Approved",
            ["statusFeatured"+N+"Title"]: "Protected",
            ["statusCreated"+N+"Title"]: "Created",

            ["banned"+Ns+"Title"]: "Banned "+ns,
            ["deleted"+Ns+"Title"]: "Deleted "+ns,
            ["missingData"+Ns+"Title"]: "Missing data",
            ["protected"+Ns+"Title"]: "Protected "+ns,
            ["lowAuthorStatus"+Ns+"Title"]: "Author low status",

            ["select"+Ns]: "Select "+ns,
            ["select"+N]: "Select a "+n
        }
    };
}
